import { useMemo, useState } from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";

import { linePropType } from "types/market";

import { CHARTS, CHARTS_B } from "../../constants";

const useStyles = makeStyles((theme) => ({
  selectButton: {
    backgroundColor: "#2E2E2E",
    borderRadius: 4,
    padding: "10px 16px",
  },
  menuPaper: {
    marginTop: 64,
    backgroundColor: "#2E2E2E",
  },
  menuItem: {
    color: theme.palette.common.white,

    "& > .MuiFormControlLabel-root": {
      width: "100%",
    },
  },
}));

const ChartLinesSelect = ({ activeLines, onChange, type }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const charts = useMemo(
    () => activeLines.map(({ title }) => title),
    [activeLines]
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLineChange = (chart) => () => {
    const isExisted = Boolean(
      activeLines.find((item) => item.title === chart.title)
    );

    if (isExisted) {
      onChange((prevState) =>
        [...prevState].filter(({ title }) => title !== chart.title)
      );
    } else {
      onChange((prevState) => [...prevState, chart]);
    }
  };

  return (
    <>
      <Button
        aria-controls="select"
        aria-haspopup="true"
        onClick={handleClick}
        endIcon={anchorEl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        className={classes.selectButton}
      >
        Select
      </Button>
      <Menu
        id="select"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        classes={{
          paper: classes.menuPaper,
        }}
      >
        {(type === 1 ? CHARTS : CHARTS_B).map((chart) => {
          const isActive = charts.includes(chart.title);

          return (
            <MenuItem
              key={chart.title}
              className={classes.menuItem}
              disableRipple
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isActive}
                    onChange={handleLineChange(chart)}
                  />
                }
                label={chart.title}
              />
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

ChartLinesSelect.propTypes = {
  activeLines: PropTypes.arrayOf(linePropType).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ChartLinesSelect;
