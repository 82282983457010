import PropTypes from "prop-types";

import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import clsx from "clsx";

import { linePropType } from "types/market";

import { NumericText } from "components";

import { useMarketIndexTotal } from "../../hooks";

import { CHARTS } from "../../constants";

const useStyles = makeStyles((theme) => ({
  tabButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline",
    height: "100%",
    textAlign: "left",
    backgroundColor: "#191919",
    padding: theme.spacing(3),
    color: "#6E747B",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "18px",
    border: "1px solid #434849",
    borderRadius: "10px 10px 0px 0px",

    "&:hover": {
      backgroundColor: "#2C2C2C",
    },
  },
  buttonWrapper: {
    minHeight: 65,
    "& > span": {
      display: "flex",
      alignItems: "center",
    },
  },
  activeTabButton: {
    backgroundColor: "#2C2C2C",
  },
  tabColor: {
    width: 4,
    height: 20,
    borderRadius: 4,
  },
  numericValue: {
    color: "#E9E9E9",
    fontSize: 22,
    lineHeight: "29px",
    marginLeft: theme.spacing(1),
  },
}));

const IndexMarketTabs = ({ activeTab, onTabChange }) => {
  const classes = useStyles();
  const { data, isLoading } = useMarketIndexTotal();

  if (isLoading) {
    return null;
  }

  const formattedTotals = data.reduce(
    (acc, item) => ({ ...acc, [item.kind]: item.amount.rounded }),
    {}
  );

  const handleTabClick = (newTab) => () => {
    onTabChange(newTab);
  };

  return (
    <Grid container spacing={1} wrap="nowrap">
      {CHARTS.map((chart) => {
        const isActiveTab = activeTab.title === chart.title;

        return (
          <Grid item key={chart.title}>
            <ButtonBase
              className={clsx(classes.tabButton, {
                [classes.activeTabButton]: isActiveTab,
              })}
              disableRipple
              onClick={handleTabClick(chart)}
            >
              <Box className={classes.buttonWrapper}>
                <span>
                  <div
                    style={{ backgroundColor: chart.color }}
                    className={classes.tabColor}
                  />

                  <span className={classes.numericValue}>
                    <NumericText value={formattedTotals[chart.value]} />
                  </span>
                </span>

                {chart.title}
              </Box>
            </ButtonBase>
          </Grid>
        );
      })}
    </Grid>
  );
};

IndexMarketTabs.propTypes = {
  activeTab: linePropType.isRequired,
  onTabChange: PropTypes.func.isRequired,
};
export default IndexMarketTabs;
