import PropTypes from "prop-types";

export const percentChangePropType = PropTypes.shape({
  percent: PropTypes.number,
  changeType: PropTypes.oneOf(["greater", "lower", "equals"]),
});

export const tokenPropType = PropTypes.shape({
  name: PropTypes.string,
  collateral: PropTypes.string,
  percentChange: percentChangePropType,
  quantityPerIndex: PropTypes.number,
  priceAllocation: PropTypes.number,
  totalPricePerIndex: PropTypes.number,
  tokenPrice: PropTypes.number,
  logo: PropTypes.string,
});
