import { useState } from "react";
import ReactGa from "react-ga";

import { coinPropType } from "types/coin";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { makeStyles } from "@material-ui/core/styles";

import { useModalState } from "hooks";
import { Dialog, WithdrawModal, BorrowModal, RepayModal } from "components";

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.common.white,

    "& svg": {
      fontSize: 20,
    },
  },
  menuPaper: {
    padding: 4,
    width: 200,
    borderRadius: 4,
  },
  menuItem: {
    width: "100%",
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "21px",
    color: theme.palette.common.white,
  },
}));

const CoinsActionsMenu = ({ data }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    isOpen: withdrawModalOpened,
    onOpen: handleWithdrawModalOpen,
    onClose: handleWithdrawModalClose,
  } = useModalState();
  const {
    isOpen: borrowModalOpened,
    onOpen: handleBorrowModalOpen,
    onClose: handleBorrowModalClose,
  } = useModalState();
  const {
    isOpen: repayModalOpened,
    onOpen: handleRepayModalOpen,
    onClose: handleRepayModalClose,
  } = useModalState();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleWithdraw = () => {
    handleWithdrawModalOpen();
    handleClose();

    ReactGa.event({
      category: "Borrower Dashboard",
      action: "Withdraw",
    });
  };

  const handleBorrow = () => {
    handleBorrowModalOpen();
    handleClose();

    ReactGa.event({
      category: "Borrower Dashboard",
      action: "Borrow",
    });
  };

  const handleRepay = () => {
    handleRepayModalOpen();
    handleClose();

    ReactGa.event({
      category: "Borrower Dashboard",
      action: "Repay",
    });
  };

  return (
    <div>
      <IconButton
        aria-label="show more"
        aria-haspopup="true"
        onClick={handleClick}
        disableRipple
        className={classes.button}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{
          paper: classes.menuPaper,
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleWithdraw} className={classes.menuItem}>
          Withdraw
        </MenuItem>
        <MenuItem onClick={handleRepay} className={classes.menuItem}>
          Repay
        </MenuItem>
        <MenuItem onClick={handleBorrow} className={classes.menuItem}>
          Borrow
        </MenuItem>
      </Menu>

      <Dialog
        open={withdrawModalOpened}
        onClose={handleWithdrawModalClose}
        noPadding
      >
        <WithdrawModal data={data} onClose={handleWithdrawModalClose} />
      </Dialog>

      <Dialog
        open={borrowModalOpened}
        onClose={handleBorrowModalClose}
        noPadding
      >
        <BorrowModal data={data} onClose={handleBorrowModalClose} />
      </Dialog>

      <Dialog open={repayModalOpened} onClose={handleRepayModalClose} noPadding>
        <RepayModal data={data} onClose={handleRepayModalClose} />
      </Dialog>
    </div>
  );
};

CoinsActionsMenu.propTypes = {
  data: coinPropType.isRequired,
};

export default CoinsActionsMenu;
