import theme from "../theme";

const MuiTooltip = {
  props: {},
  overrides: {
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      backgroundColor: theme.palette.common.black,
      fontSize: 14,
      lineHeight: "18px",
    },
  },
};

export default MuiTooltip;
