import { useQuery } from "react-query";
import sumBy from "lodash/sumBy";

import { lendingAPI } from "utils/api";
import walletInfo from "utils/ethereum";

const useLenderDashboard = () =>
  useQuery("lender-dashboard", async () => {
    const accountData = await lendingAPI.getStatsByAccount(walletInfo.account);
    const { supply, available } = accountData;
    const connected = Boolean(walletInfo.account);

    if (connected) {
      const lendingTokensDataPromises = supply.map(
        async ({ token, rounded_balance: roundedBalance }, index) => {
          const lendingTokenId = index;

          const [allowance, balance, apy] = await Promise.all([
            walletInfo.isLendingTokenAllowance(token),
            walletInfo.getLendingTokenBalance(),
            walletInfo.getSupplyAPY(),
          ]);

          return {
            allowance,
            lendingTokenId,
            lendingTokenBalance: balance,
            apy,
            supplyValue: roundedBalance,
          };
        }
      );
      const lendingTokensObjects = await Promise.all(lendingTokensDataPromises);

      const lendingTokenBalance = await walletInfo.getLendingTokenBalance();

      return {
        accountData: {
          ...accountData,
          supply: supply.map((token, index) => ({
            ...token,
            ...lendingTokensObjects[index],
          })),
          available: available.map((token, index) => ({
            ...token,
            ...lendingTokensObjects[index],
          })),
          totalSupplyValue: sumBy(supply, "rounded_balance"),
          lendingTokenBalance,
        },
      };
    }

    return {
      accountData,
    };
  });

export default useLenderDashboard;
