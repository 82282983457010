import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import floor from "lodash/floor";

import { DECIMAL_SCALE, EVENT_TYPES } from "app/constants";
import { coinPropType } from "types/coin";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";

import {
  CoinInfo,
  Spinner,
  HealthFactorProgressBar,
  DialogLogo,
  DialogApplyButton,
  DialogTotal,
  NumericText,
  NumericTextField,
} from "components";

import { useLendingTokenMutations } from "hooks/mutation";
import { ShareContext } from "pages/BorrowerNewDashboard/components/DepositedTable/DepositedTable";
import logoImage from "assets/svg/footer-icon.svg";
import icon from "assets/svg/icon.svg";
// import { ReactComponent as ExitIcon } from "assets/svg/exit.svg";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    minWidth: 400,
    color: theme.palette.primary.main,
    paddingLeft: 0,
    paddingRight: 0,
    width: 429,

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      minWidth: "100%",
      height: "100%",
      width: "100%",
    },
  },

  contentInner: {
    position: "relative",
    backgroundColor: "#F8F8F8",
    [theme.breakpoints.down("sm")]: {
      flex: 1,
    },
  },
  supplyBox: {
    borderBottom: "1px solid #E0E0E0",
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  supplyRates: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "18px",
  },
}));

const BorrowModal = ({
  data: {
    name,
    healthFactor,
    address,
    data,
    data: { currentBorrowingLevel },
    distributionAPY,
  },
  onClose,
  openShare,
}) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState("");
  const { setShareData } = useContext(ShareContext);
  const { isLoading, borrow } = useLendingTokenMutations({
    name,
    amount: inputValue,
    kind: EVENT_TYPES.borrow,
  });

  const pitAmount = data?.pitRemaining?.rounded;
  const borrowingLevel = currentBorrowingLevel
    ? currentBorrowingLevel.rounded
    : 0;

  const maxValueFloor = floor(pitAmount, DECIMAL_SCALE);

  const maxBorrowingValue =
    borrowingLevel !== 0 ? borrowingLevel : maxValueFloor;

  const maxValue =
    maxValueFloor <= maxBorrowingValue ? maxValueFloor : borrowingLevel;

  const isDisabled =
    !inputValue || Number(inputValue) === 0 || inputValue > maxValue;

  const resetInputValue = () => setInputValue("");
  const handleBorrow = async () => {
    const isMax = Number(inputValue) === Number(maxValue);

    try {
      await borrow({
        prjAmount: inputValue,
        prjAddress: address,
        isMax,
      });
      setShareData({
        token: data.lendingAsset,
        amount: inputValue,
        apy: distributionAPY?.rounded.toFixed(2) || 0,
        colToken: name,
      });
      onClose();
      openShare();
    } catch (error) {
      console.log(error);
    } finally {
      resetInputValue();
    }
  };

  return (
    <>
      <DialogLogo
        logoUrl="./assets/coins_list/usd-coin.svg"
        name={data.lendingAsset}
      />

      {isLoading && <Spinner position="absolute" color="success" />}

      <Box pt={5} p={0} className={classes.rootContainer}>
        <NumericTextField
          value={inputValue}
          onChange={setInputValue}
          maxValue={maxValue}
          decimalScale={DECIMAL_SCALE}
        />

        <Box px={2} pb={2} mt={2} className={classes.contentInner}>
          <Box className={classes.supplyBox} style={{ paddingTop: "10px" }}>
            <Grid container alignItems="center" justify="space-between">
              <Grid item md={6}>
                <CoinInfo logoUrl={icon}>Supply APY</CoinInfo>
              </Grid>
              <Grid item>
                <Typography color="primary">
                  <NumericText
                    value={distributionAPY.rounded}
                    precision={2}
                    suffix="%"
                  />
                </Typography>
              </Grid>
            </Grid>
          </Box>

          {/* <Box py={2}>
            <Typography color="textSecondary" className={classes.supplyRates}>
              Supply Rates <ExitIcon />
            </Typography>
          </Box> */}

          <Box className={classes.supplyBox}>
            <Grid container alignItems="center" justify="space-between">
              <Grid item md={6}>
                <CoinInfo logoUrl={logoImage}>Distribution APY</CoinInfo>
              </Grid>
              <Grid item>
                <Typography color="primary">
                  <NumericText
                    value={distributionAPY.rounded}
                    precision={2}
                    suffix="%"
                  />
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <HealthFactorProgressBar value={healthFactor} />
        </Box>

        <Box>
          <DialogApplyButton disabled={isDisabled} onClick={handleBorrow}>
            Borrow
          </DialogApplyButton>
          <DialogTotal>
            <Grid container justify="space-between">
              <Grid item>
                <Typography color="secondary">PIT Amount</Typography>
              </Grid>
              <Grid item>
                <Typography color="secondary">
                  <NumericText value={pitAmount} suffix="PIT" />
                </Typography>
              </Grid>

              {currentBorrowingLevel && (
                <>
                  <Grid item>
                    <Typography color="secondary">
                      Borrowing limit for this collateral
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography color="secondary">
                      <NumericText
                        value={currentBorrowingLevel.decimal}
                        moneyValue
                      />
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </DialogTotal>
        </Box>
      </Box>
    </>
  );
};

BorrowModal.propTypes = {
  data: coinPropType.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default BorrowModal;
