import { useQuery } from "react-query";

import { marketAPI } from "utils/api";

const useMarketIndexTokens = () =>
  useQuery("market-tokens", async () => {
    const { data } = await marketAPI.getTokens();

    return data;
  });

export default useMarketIndexTokens;
