export const SELECT = {
  tokensIssued: "total_pit",
  totalAmount: "total_amount_collateral_deposited",
  totalOutstanding: "total_outstanding",
  ratio: "collateral_vs_loan_ration",
  borrowingAPY: "apy",
  lenderAPY: "lender_apy",
  totalSupply: "total_supply",
};

export const INTERVAL = {
  HOUR: "hour",
  DAY: "day",
  WEEK: "week",
  MONTH: "month",
  YEAR: "year",
  // YEARS: "5years",
};

export const INTERVALS = [
  {
    name: "1H",
    value: INTERVAL.HOUR,
  },
  {
    name: "1D",
    value: INTERVAL.DAY,
  },
  {
    name: "1W",
    value: INTERVAL.WEEK,
  },
  {
    name: "1M",
    value: INTERVAL.MONTH,
  },
  {
    name: "1Y",
    value: INTERVAL.YEAR,
  },
  // {
  //   name: "5Y",
  //   value: INTERVAL.YEARS,
  // },
];

export const DEFAULT_INTERVAL_VALUE = INTERVAL.YEAR;

export const CHARTS = [
  {
    title: "Network Total PIT Tokens issued",
    color: "#14A38B",
    value: SELECT.tokensIssued,
  },
  {
    title: "Total Amount collaterals deposited",
    color: "#FF7171",
    value: SELECT.totalAmount,
  },
  {
    title: "Total Outstanding",
    color: "#F2AC57",
    value: SELECT.totalOutstanding,
  },
  {
    title: "Collateral vs open loans ratio",
    color: "#A5A6F6",
    value: SELECT.ratio,
  },
  {
    title: "Borrowing APY",
    color: "#EF5DA8",
    value: SELECT.borrowingAPY,
  },
  {
    title: "Lender APY",
    color: "#78FF56",
    value: SELECT.lenderAPY,
  },
  {
    title: "Lender aggregate capital deposits (USDC)",
    color: "#FFF494",
    value: SELECT.totalSupply,
  },
];
