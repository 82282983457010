// import { CHAINID_NETWORK } from "constants/NetworkChainId";

export const TRANSACTION_TIMEOUT_MS = 10000; // 10s

export const CHAIN_ID = process.env.REACT_APP_CHAIN_ID;

const message = {
  5: "Please choose the Ethereum Network",
  1: "Please choose the Ethereum Main Network",
  80001: "Please choose the Polygon Network",
  420: "Please choose the Optimism Network",
  421613: "Please choose the Arbitrum Network",
};

export const CHAIN_ERROR_MESSAGE = message[+CHAIN_ID || 5];

export const DECIMAL_SCALE = 6;

export const CHART_COLORS = {
  total_pit: "#14A38B",
  total_amount_collateral_deposited: "#FF7171",
  total_outstanding: "#F2AC57",
  collateral_vs_loan_ration: "#A5A6F6",
  apy: "#EF5DA8",
  lender_apy: "#78FF56",
  total_supply: "#FFF494",
};

export const EVENT_TYPES = {
  deposit: "Deposit",
  borrow: "Borrowed",
  withdraw: "Withdraw",
  repay: "Repay",
};
