import { useState, useContext } from "react";
import PropTypes from "prop-types";
import floor from "lodash/floor";

import { DECIMAL_SCALE, EVENT_TYPES } from "app/constants";
import { lendingTokenPropType } from "types/lendingToken";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Grid } from "@material-ui/core";

import {
  Spinner,
  CoinInfo,
  NumericText,
  DialogLogo,
  DialogApplyButton,
  DialogTotal,
  NumericTextField,
} from "components";

import { useWallet } from "hooks";
import { useLendingTokenMutations } from "hooks/mutation";
import logoImage from "assets/svg/footer-icon.svg";
import { ShareLendingContext } from "../SuppliedTable/SuppliedTable";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    minWidth: 400,
    color: theme.palette.primary.main,
    paddingLeft: 0,
    paddingRight: 0,
    width: 429,

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      minWidth: "100%",
      height: "100%",
      width: "100%",
    },
  },
  contentInner: {
    position: "relative",
    backgroundColor: "#F8F8F8",
    [theme.breakpoints.down("sm")]: {
      flex: 1,
    },
  },
}));

const RedeemModal = ({
  data: { name, supplyValue, apy, lendingTokenBalance, symbol },
  onClose,
  openShare,
}) => {
  const classes = useStyles();
  const { connected } = useWallet();
  const [inputValue, setInputValue] = useState("");
  const { setShareData } = useContext(ShareLendingContext);
  const { isLoading, redeemUnderlying, redeem } = useLendingTokenMutations({
    name,
    amount: inputValue,
    kind: EVENT_TYPES.withdraw,
  });
  const maxValue = floor(supplyValue, DECIMAL_SCALE);
  const isDisabled =
    !connected ||
    !inputValue ||
    Number(inputValue) === 0 ||
    Number(inputValue) > Number(maxValue);

  const resetInputValue = () => setInputValue("");

  const handleSubmit = async () => {
    try {
      const isMaxValue = Number(inputValue) === maxValue;

      if (isMaxValue) {
        await redeem(lendingTokenBalance.toString());
      } else {
        await redeemUnderlying(inputValue);
      }
      setShareData({
        token: symbol,
        amount: inputValue,
        apy,
        colToken: name,
      });
      onClose();
      openShare();
    } finally {
      resetInputValue();
    }
  };
  return (
    <>
      <DialogLogo logoUrl="./assets/coins_list/usd-coin.svg" name={symbol} />

      {isLoading && <Spinner position="absolute" color="success" />}

      <Box pt={5} p={0} className={classes.rootContainer}>
        <NumericTextField
          value={inputValue}
          onChange={setInputValue}
          maxValue={maxValue}
          decimalScale={DECIMAL_SCALE}
        />

        <Box className={classes.contentInner} mt={2}>
          <Box py={2} px={2}>
            <Grid container alignItems="center" justify="space-between">
              <Grid item md={6}>
                <CoinInfo logoUrl={logoImage}>Supply APY</CoinInfo>
              </Grid>
              <Grid item>
                <Typography color="primary">
                  <NumericText value={apy} suffix="%" />
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box>
          <DialogApplyButton disabled={isDisabled} onClick={handleSubmit}>
            Withdraw
          </DialogApplyButton>
          <DialogTotal
            title="Wallet Balance"
            value={supplyValue}
            currency={symbol}
          />
        </Box>
      </Box>
    </>
  );
};

RedeemModal.propTypes = {
  data: lendingTokenPropType.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RedeemModal;
