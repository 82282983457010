import axios from "utils/axios";

const DEFAULT_WALLET_ACCOUNT = "0x0000000000000000000000000000000000000000";

export const platformAPI = {
  getStats() {
    return axios.get("/platform-stats");
  },
};

export const coinAPI = {
  getCoins() {
    return axios.get("/currency/coins");
  },
};

export const marketAPI = {
  getMarketIndexData(select, startAt) {
    return axios.get("/market/lines", {
      params: {
        select,
        start_at: startAt,
      },
    });
  },
  getTotals(select) {
    return axios.get("market/totals", {
      params: {
        select,
      },
    });
  },
  getTokens() {
    return axios.get("market/tokens");
  },
};

export const borrowMarketAPI = {
  getCoins(walletAccount) {
    return axios
      .get(`/borrow-dashboard/${walletAccount.substring(2)}/coins`)
      .then((res) => res)
      .catch((err) => {
        console.log("e", err);
        return {
          data: [],
        };
      });
  },
};

export const lendingAPI = {
  getStatsByAccount(walletAccount) {
    return axios.get(
      `/lending/${walletAccount || DEFAULT_WALLET_ACCOUNT}/stats`
    );
  },
  getBalancesByAccount(walletAccount) {
    return axios.get(
      `/lending/${walletAccount || DEFAULT_WALLET_ACCOUNT}/balances`
    );
  },
};
