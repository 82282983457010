import { useQuery } from "react-query";

import walletInfo from "utils/ethereum";

const useAccountBalance = () =>
  useQuery("account-balance", async () => {
    try {
      const connected = Boolean(walletInfo.account);

      if (connected) {
        const { balance, symbol } = await walletInfo.getLendingTokenBalanceOf();

        return [{ balance, symbol }];
      }

      return [
        {
          balance: 0,
          symbol: "USDCTest",
        },
      ];
    } catch (error) {
      return [
        {
          balance: 0,
          symbol: "USDCTest",
        },
      ];
    }
  });

export default useAccountBalance;
