import { createMuiTheme } from "@material-ui/core/styles";

import globalTheme from "./theme";

import {
  MuiButton,
  MuiSwitch,
  MuiDialog,
  MuiPaper,
  MuiLinearProgress,
  MuiCircularProgress,
  MuiTableCell,
  MuiContainer,
  MuiLink,
  MuiTypography,
  MuiDrawer,
  MuiBreadcrumbs,
  MuiTabs,
  MuiTab,
  MuiDialogContent,
  MuiInput,
  MuiAppBar,
  MuiTooltip,
  MuiSkeleton,
} from "./components";

const theme = createMuiTheme(
  {
    // default props for global components
    props: {
      MuiButton: MuiButton.props,
      MuiSwitch: MuiSwitch.props,
      MuiDialog: MuiDialog.props,
      MuiPaper: MuiPaper.props,
      MuiLinearProgress: MuiLinearProgress.props,
      MuiCircularProgress: MuiCircularProgress.props,
      MuiTableCell: MuiTableCell.props,
      MuiContainer: MuiContainer.props,
      MuiLink: MuiLink.props,
      MuiTypography: MuiTypography.props,
      MuiDrawer: MuiDrawer.props,
      MuiBreadcrumbs: MuiBreadcrumbs.props,
      MuiTabs: MuiTabs.props,
      MuiTab: MuiTab.props,
      MuiDialogContent: MuiDialogContent.props,
      MuiInput: MuiInput.props,
      MuiAppBar: MuiAppBar.props,
      MuiTooltip: MuiTooltip.props,
      MuiSkeleton: MuiSkeleton.props,
    },
    overrides: {
      MuiButton: MuiButton.overrides,
      MuiSwitch: MuiSwitch.overrides,
      MuiDialog: MuiDialog.overrides,
      MuiPaper: MuiPaper.overrides,
      MuiLinearProgress: MuiLinearProgress.overrides,
      MuiCircularProgress: MuiCircularProgress.overrides,
      MuiTableCell: MuiTableCell.overrides,
      MuiContainer: MuiContainer.overrides,
      MuiLink: MuiLink.overrides,
      MuiTypography: MuiTypography.overrides,
      MuiDrawer: MuiDrawer.overrides,
      MuiBreadcrumbs: MuiBreadcrumbs.overrides,
      MuiTabs: MuiTabs.overrides,
      MuiTab: MuiTab.overrides,
      MuiDialogContent: MuiDialogContent.overrides,
      MuiInput: MuiInput.overrides,
      MuiAppBar: MuiAppBar.overrides,
      MuiTooltip: MuiTooltip.overrides,
      MuiSkeleton: MuiSkeleton.overrides,
    },
  },
  globalTheme
);

export default theme;
