import {
  BUSDC_CONTRACT,
  CHAINID_NETWORK,
  LENDING_TOKEN_ADDRESS,
  PRICE_PROVIDER_AGGREGATOR_CONTRACT,
  PRIMARY_INDEX_TOKEN,
} from "constants/NetworkChainId";
import {
  bUSDCContractABI,
  PriceProviderAggregatorABI,
  PrimaryIndexTokenABI,
} from "./abi";

export const PrimaryIndexToken = {
  address: PRIMARY_INDEX_TOKEN[CHAINID_NETWORK.ethereum],
  abi: PrimaryIndexTokenABI,
};

export const bUSDCContract = {
  address: BUSDC_CONTRACT[CHAINID_NETWORK.ethereum],
  abi: bUSDCContractABI,
};

export const PriceProviderAggregatorContract = {
  address: PRICE_PROVIDER_AGGREGATOR_CONTRACT[CHAINID_NETWORK.ethereum],
  abi: PriceProviderAggregatorABI,
};

export const lendingTokenAddress =
  LENDING_TOKEN_ADDRESS[CHAINID_NETWORK.ethereum];
