import { useMemo } from "react";
import PropTypes from "prop-types";
import orderBy from "lodash/orderBy";

import { Box, Grid } from "@material-ui/core";

import { coinPropType } from "types/coin";
import { PaperTitle } from "components";

import { DepositedTable, BorrowedTable } from "..";

const DepositedBorrowedTable = ({ data }) => {
  const showBorrowedTitle = useMemo(
    () => data.some((coin) => coin.data.loanBalance),
    [data]
  );
  const sortedData = useMemo(
    () => orderBy(data, ["totalOutstanding"], ["desc"]),
    [data]
  );

  return (
    <>
      <Box mb={2}>
        <Grid container spacing={2}>
          {sortedData.map((coin, index) => (
            <Grid key={coin.uuid} container item spacing={7}>
              <Grid item md={6}>
                {index === 0 && <PaperTitle>Deposited</PaperTitle>}
                <DepositedTable data={coin} />
              </Grid>

              <Grid item md={6}>
                {index === 0 && showBorrowedTitle && (
                  <PaperTitle>Borrowed</PaperTitle>
                )}

                {coin.data.loanBalance && <BorrowedTable data={coin} />}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

DepositedBorrowedTable.propTypes = {
  data: PropTypes.arrayOf(coinPropType).isRequired,
};

export default DepositedBorrowedTable;
