import { useCallback, useMemo } from "react";
import ReactGa from "react-ga";
import clsx from "clsx";

import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { useModalState } from "hooks";
import { CoinInfo, Dialog, NumericText, RepayModal, Table } from "components";

import { ReactComponent as ChevronDown } from "assets/svg/chevron-down.svg";

const useStyles = makeStyles(() => ({
  table: {
    "& th:nth-child(3)": {
      minWidth: 150,
    },
  },
  opened: {
    transform: "scale(-1)",
  },
  coinInfoCell: {
    display: "flex",
  },
  chevronButton: {
    paddingLeft: 0,
    paddingRight: 6,
  },
  coinInfoWrapper: {
    minWidth: 120,
  },
  tableHeading: {
    marginLeft: 0,
  },
  title: {
    color: "#4F4F4F",
    fontSize: 14,
    fontWeight: 500,
  },
}));

const BorrowedTable = ({ data }) => {
  const classes = useStyles();
  const { isOpen, onOpen, onClose } = useModalState();

  const handleClick = useCallback(() => {
    onOpen();

    ReactGa.event({
      category: "Borrower Dashboard",
      action: "Repay",
    });
  }, [onOpen]);

  const columns = useMemo(
    () => [
      {
        Header: "Asset",
        accessor: "asset",
        Cell: ({
          row: {
            original: {
              data: { lendingAsset },
            },
            getToggleRowExpandedProps,
            isExpanded,
          },
        }) => (
          <div className={classes.coinInfoCell}>
            <IconButton
              {...getToggleRowExpandedProps()}
              className={classes.chevronButton}
            >
              <ChevronDown className={clsx({ [classes.opened]: isExpanded })} />
            </IconButton>

            <div className={classes.coinInfoWrapper}>
              <CoinInfo logoUrl="./assets/coins_list/usd-coin.svg">
                {lendingAsset}
              </CoinInfo>
            </div>
          </div>
        ),
      },
      {
        Header: "Accrual",
        accessor: "accrual",
        Cell: ({
          row: {
            original: {
              data: { accrual },
            },
          },
        }) => {
          if (accrual?.rounded) {
            return <NumericText value={accrual?.rounded || ""} precision={4} />;
          }

          return <Typography align="center">-</Typography>;
        },
      },
      {
        Header: "Total Outstanding",
        accessor: "totalOutstanding",
        props: {
          width: 90,
        },
        Cell: ({
          row: {
            original: {
              data: { totalOutstanding },
            },
          },
        }) => (
          <NumericText value={totalOutstanding?.rounded || ""} precision={4} />
        ),
      },

      {
        Header: "",
        accessor: "button",
        props: {
          align: "right",
        },
        Cell: () => (
          <>
            <Button style={{ display: "none" }} onClick={handleClick}>
              ...
            </Button>
            <div>...</div>
          </>
        ),
      },
    ],
    [handleClick, classes]
  );

  const renderRowSubComponent = ({ row }) => {
    const {
      data: { apy, loanBalance },
    } = row.original;

    return (
      <Box my={2}>
        <Grid container>
          <Grid
            item
            md={12}
            container
            spacing={4}
            className={classes.tableHeading}
          >
            <Grid item>
              <Typography color="primary" className={classes.title}>
                APY
              </Typography>
              <Typography color="secondary">
                <NumericText value={apy?.decimal || ""} suffix="%" />
              </Typography>
            </Grid>

            <Grid item>
              <Typography color="primary" className={classes.title}>
                Loan Balance
              </Typography>
              <Typography color="secondary">
                <NumericText value={loanBalance?.rounded || ""} precision={4} />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <Paper>
      <Table
        columns={columns}
        data={[data]}
        renderRowSubComponent={renderRowSubComponent}
        className={classes.table}
      />

      <Dialog open={isOpen} onClose={onClose} noPadding>
        <RepayModal data={data} onClose={onClose} />
      </Dialog>
    </Paper>
  );
};

export default BorrowedTable;
