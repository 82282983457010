import { useQuery } from "react-query";
import { borrowMarketAPI, coinAPI, platformAPI } from "utils/api";
import walletInfo from "utils/ethereum";
import sumBy from "lodash/sumBy";
import { lendingTokenAddress } from "utils/ethereum/contracts";

const defaultValue = {
  coins: [],
  totalPitAmount: 0,
  infoData: {
    depositedTotalBalance: 0,
    borrowingCapacity: 0,
    totalOutstanding: 0,
    aggregatedAccruedInterest: 0,
  },
  lendingTokens: {},
  isError: true,
};

const useActionsLog = () =>
  useQuery("deposited-borrowed", async () => {
    try {
      const [
        { data },
        {
          data: { apy: distributionAPY },
        },
      ] = await Promise.all([coinAPI.getCoins(), platformAPI.getStats()]);

      const connected = Boolean(walletInfo.account);

      if (connected) {
        const { data: symbolCoinMap } = await borrowMarketAPI.getCoins(
          walletInfo.account
        );

        if (symbolCoinMap === null) return defaultValue;

        const isLendingTokenAllowance =
          await walletInfo.isLendingTokenAllowance(lendingTokenAddress);

        const formattedData = data.map(async (item, index) => {
          const [
            balance,
            allowance,
            tokenPrice,
            pitAmount,
            prjAmount,
            totalInIndex,
          ] = await Promise.all([
            walletInfo.getBalance(item.address),
            walletInfo.isTokenAllowance(item.address),
            walletInfo.getPrice(item.address),
            walletInfo.getPitAmount(item.address),
            walletInfo.getPrjAmount(item.address),
            walletInfo.getTotalInIndex(item.address),
          ]);

          const prjId = index;

          const healthFactor =
            symbolCoinMap[item.name]?.healthFactor?.rounded || 0;
          const coinObject = symbolCoinMap[item.symbol] || null;

          let collateralBalance;
          if (coinObject) {
            const [collateralBalanceAmountObject, symbol] =
              coinObject?.collateralBalance;
            const collateralBalanceAmount = Number(
              collateralBalanceAmountObject.decimal || "0"
            );

            const collateralBalanceAmountUsd =
              collateralBalanceAmount * tokenPrice;

            const amount = {
              rounded: collateralBalanceAmountUsd,
              decimal: collateralBalanceAmountUsd.toString(),
            };

            collateralBalance = [collateralBalanceAmount, symbol, amount];
            coinObject.collateralBalance = collateralBalance;
          }

          return {
            ...item,
            balance,
            allowance,
            healthFactor,
            price: balance * tokenPrice,
            tokenPrice,
            prjId,
            pitAmount,
            prjAmount,
            totalInIndex,
            lendingToken: { allowance: isLendingTokenAllowance },
            data: coinObject || {},
            distributionAPY,
          };
        });

        const coins = await Promise.all(formattedData);

        const totalPitAmount = sumBy(coins, "pitAmount");

        const infoData = {
          depositedTotalBalance: sumBy(
            coins.map((coin) => {
              const balance = coin?.data?.collateralBalance;

              if (balance) {
                return balance[2].rounded;
              }

              return 0;
            })
          ),
          borrowingCapacity: sumBy(
            coins.map((coin) => coin?.data?.pitRemaining?.rounded || 0)
          ),
          totalOutstanding: sumBy(
            coins.map((coin) => coin?.data?.totalOutstanding?.rounded || 0)
          ),
          aggregatedAccruedInterest: sumBy(
            coins.map((coin) => coin?.data?.accrual?.rounded || 0)
          ),
        };

        return { ...defaultValue, coins, totalPitAmount, infoData };
      }

      return {
        ...defaultValue,
        coins: data,
        isError: false,
      };
    } catch (error) {
      console.log("error", error);

      return defaultValue;
    }
  });

export default useActionsLog;
