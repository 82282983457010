import { useMemo } from "react";
import clsx from "clsx";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import ReactGa from "react-ga";

import { coinPropType } from "types/coin";

import {
  Typography,
  Paper,
  IconButton,
  Box,
  Grid,
  Button,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";

import { useModalState } from "hooks";
import { BorrowModal, CoinInfo, Dialog, NumericText, Table } from "components";

import { ReactComponent as ArrowFillRight } from "assets/svg/arrow-fill-right.svg";
import { ReactComponent as ChevronDown } from "assets/svg/chevron-down.svg";
import { ReactComponent as DangerIcon } from "assets/svg/danger.svg";

import { CoinActionsMenu, LogsTable } from "..";

const useStyles = makeStyles((theme) => ({
  table: {
    "& th": {
      whiteSpace: "nowrap",
    },
  },
  tableWrapper: {
    position: "relative",
  },
  opened: {
    transform: "scale(-1)",
  },
  coinInfoCell: {
    display: "flex",
  },
  chevronButton: {
    paddingLeft: 0,
    paddingRight: 6,
  },
  coinInfoWrapper: {
    minWidth: 120,
  },

  addButton: {
    position: "absolute",
    overflow: "hidden",
    transition: theme.transitions.main,
    minWidth: 40,
    width: 40,
    height: 40,
    paddingLeft: 10,
    paddingRight: 10,
    top: 56,
    left: "calc(100% + 8px)",

    "& .MuiButton-startIcon": {
      position: "absolute",
      left: 10,
      margin: 0,
    },

    "&:hover": {
      transition: theme.transitions.main,
      width: 90,

      "& $hiddenText": {
        transition: theme.transitions.main,
        opacity: 1,
      },
    },
  },
  hiddenText: {
    position: "absolute",
    left: 30,
    opacity: 0,
    transition: theme.transitions.main,
  },
  arrowButton: {
    background: "transparent",
    zIndex: 999,

    "& .MuiButton-startIcon": {
      visibility: "hidden",
    },

    "&:hover": {
      width: 40,

      "& .MuiButton-startIcon": {
        visibility: "visible",
      },

      "& $hiddenIcon": {
        visibility: "hidden",
      },
    },
  },
  hiddenIcon: {
    paddingTop: 8,
  },
  title: {
    color: "#4F4F4F",
    fontSize: 14,
    fontWeight: 500,
    marginRight: 40,
  },
  tableHeading: {
    borderBottom: "1px solid #434849",
    marginLeft: 0,
  },
}));

const DepositedTable = ({ data }) => {
  const classes = useStyles();
  const { isOpen, onOpen, onClose } = useModalState();
  const columns = useMemo(
    () => [
      {
        Header: () => <Box ml={2}>Asset</Box>,
        accessor: "name",
        Cell: ({
          value,
          row: {
            original: {
              logo,
              healthFactor,
              data: { logs = [] },
            },
            getToggleRowExpandedProps,
            isExpanded,
          },
        }) => (
          <div className={classes.coinInfoCell}>
            {logs.length > 0 ? (
              <IconButton
                {...getToggleRowExpandedProps()}
                className={classes.chevronButton}
              >
                <ChevronDown
                  className={clsx({ [classes.opened]: isExpanded })}
                />
              </IconButton>
            ) : (
              <Box ml={2} />
            )}

            <div className={classes.coinInfoWrapper}>
              <CoinInfo logoUrl={logo} healthFactor={healthFactor}>
                {value}
              </CoinInfo>
            </div>
          </div>
        ),
      },
      {
        Header: () => (
          <Box display="flex" alignItems="center">
            LVR
            <Tooltip title="Loan to value ratio" arrow placement="top">
              <DangerIcon />
            </Tooltip>
          </Box>
        ),
        accessor: "lvr",
        Cell: ({
          row: {
            original: {
              data: { lvr },
            },
          },
        }) => <>{lvr?.decimal}</>,
      },
      {
        Header: "Collateral Balance",
        accessor: "collateralBalance",
        Cell: ({
          row: {
            original: {
              data: { collateralBalance },
            },
          },
        }) => {
          if (!collateralBalance) return null;

          const [value, symbol, balance] = collateralBalance;

          return (
            <>
              <NumericText
                value={value.rounded}
                suffix={symbol}
                precision={4}
              />
              <Typography>
                <NumericText value={balance.rounded} precision={4} moneyValue />
              </Typography>
            </>
          );
        },
      },
      {
        Header: "PIT Remaining",
        accessor: "pitRemaining",
        Cell: ({
          row: {
            original: {
              data: { pitRemaining },
            },
          },
        }) => (
          <NumericText
            value={pitRemaining?.decimal}
            precision={4}
            suffix="PIT"
          />
        ),
      },

      {
        Header: "",
        accessor: "actions",
        props: {
          align: "right",
        },
        Cell: ({ row: { original } }) => (
          <>
            <CoinActionsMenu data={original} />
          </>
        ),
      },
    ],
    [classes]
  );

  const renderRowSubComponent = ({ row }) => {
    const { logs = [] } = row.original.data;

    if (!logs.length) {
      return null;
    }

    const {
      pitAmount,
      data: { depositDate, liquidationThreashold, liquidationAmount },
    } = row.original;

    const depositDateFormatted = depositDate
      ? format(parseISO(depositDate.split("T")[0]), "dd.MM.yyyy")
      : "";

    return (
      <Box mt={2}>
        <Grid container>
          <Grid
            item
            md={12}
            container
            spacing={4}
            className={classes.tableHeading}
          >
            <Grid item>
              <Typography color="primary" className={classes.title}>
                Deposit Date
              </Typography>
              <Typography color="secondary">{depositDateFormatted}</Typography>
            </Grid>

            <Grid item>
              <Typography color="primary" className={classes.title}>
                Liquidation Threshold
              </Typography>
              <Typography color="secondary">
                <NumericText
                  value={liquidationThreashold.rounded}
                  precision={4}
                />
                {liquidationAmount && (
                  <>
                    {" "}
                    |{" "}
                    <NumericText
                      value={liquidationAmount.rounded}
                      precision={4}
                    />
                  </>
                )}
              </Typography>
            </Grid>

            <Grid item>
              <Typography color="primary" className={classes.title}>
                PIT Issued
              </Typography>
              <Typography color="secondary">
                <NumericText value={pitAmount} suffix="PIT" precision={4} />
              </Typography>
            </Grid>
          </Grid>

          <Grid item>
            <LogsTable logs={logs} />
          </Grid>
        </Grid>
      </Box>
    );
  };

  const handleBorrow = () => {
    onOpen();

    ReactGa.event({
      category: "Borrower Dashboard",
      action: "Borrow",
    });
  };

  return (
    <div className={classes.tableWrapper}>
      <Paper>
        <Table
          columns={columns}
          data={[data]}
          renderRowSubComponent={renderRowSubComponent}
          className={classes.table}
        />
      </Paper>

      {data.data.lendingAsset ? (
        <Button
          startIcon={<AddIcon />}
          className={clsx(classes.addButton, classes.arrowButton)}
          onClick={handleBorrow}
        >
          <span className={classes.hiddenIcon}>
            <ArrowFillRight />
          </span>
        </Button>
      ) : (
        <Button
          startIcon={<AddIcon />}
          className={classes.addButton}
          onClick={handleBorrow}
        >
          <span className={classes.hiddenText}>Borrow</span>
        </Button>
      )}

      <Dialog open={isOpen} onClose={onClose} noPadding>
        <BorrowModal data={data} onClose={onClose} />
      </Dialog>
    </div>
  );
};

DepositedTable.propTypes = {
  data: coinPropType.isRequired,
};

export default DepositedTable;
