// import PropTypes from "prop-types";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";

import { useWallet } from "hooks";
import { NumericText } from "components";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    fontFamily: "Mulish",
    lineHeight: "21px",
    color: "#434849",
  },
  subTitle: {
    fontSize: 26,
    fontFamily: "Mulish",
    lineHeight: "36px",
    color: "#AFB0B1",

    marginTop: theme.spacing(2),
  },
  divider: {
    backgroundColor: "#434849",
    marginLeft: 20,
    marginRight: 20,
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),

    "& > .MuiGrid-item": {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  price: {
    fontSize: 52,
    fontFamily: "Mulish",
    marginTop: theme.spacing(1),
    lineHeight: "64px",
  },
  priceTitle: {
    fontFamily: "Mulish",
  },
}));

const BorrowerOverview = ({
  totalPitAmount,
  infoData: {
    depositedTotalBalance,
    borrowingCapacity,
    aggregatedAccruedInterest,
    totalOutstanding,
  },
}) => {
  const classes = useStyles();
  const { connected } = useWallet();

  return (
    <Grid item container spacing={6} className={classes.container}>
      <Grid item>
        <Typography
          color="secondary"
          variant="h6"
          className={classes.priceTitle}
        >
          Remaining Borrowing Capacity
        </Typography>

        <Typography color="secondary" className={classes.price}>
          <NumericText
            value={connected ? borrowingCapacity : 0}
            precision={2}
          />
        </Typography>
      </Grid>

      <Divider orientation="vertical" flexItem className={classes.divider} />

      <Grid item>
        <Box mt={3}>
          <Typography color="primary" className={classes.title}>
            Total Deposited
          </Typography>

          <Typography color="secondary" className={classes.subTitle}>
            <NumericText
              value={connected ? depositedTotalBalance : 0}
              moneyValue
            />
          </Typography>
        </Box>
      </Grid>

      <Grid item>
        <Box mt={3}>
          <Typography color="primary" className={classes.title}>
            Tokens Issued
          </Typography>

          <Typography color="secondary" className={classes.subTitle}>
            <NumericText value={totalPitAmount} />
          </Typography>
        </Box>
      </Grid>

      <Grid item>
        <Box mt={3}>
          <Typography color="primary" className={classes.title}>
            Outstanding
          </Typography>

          <Typography color="secondary" className={classes.subTitle}>
            <NumericText value={connected ? totalOutstanding : 0} />
          </Typography>
        </Box>
      </Grid>

      <Grid item>
        <Box mt={3}>
          <Typography color="primary" className={classes.title}>
            Interest Accrued
          </Typography>

          <Typography color="secondary" className={classes.subTitle}>
            <NumericText value={connected ? aggregatedAccruedInterest : 0} />
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

// BorrowerOverview.propTypes = {
//   totalPitAmount: PropTypes.number.isRequired,
//   infoData: PropTypes.shape({
//     depositedTotalBalance: PropTypes.number,
//     borrowingCapacity: PropTypes.number,
//     aggregatedAccruedInterest: PropTypes.number,
//     totalOutstanding: PropTypes.number,
//   }).isRequired,
// };

export default BorrowerOverview;
