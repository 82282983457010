import { useState, useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";

import { Typography, Grid, Avatar, Box, Popper } from "@material-ui/core";

import { HealthFactorProgressBar, HealthTooltip } from "components";

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  avatar: {
    borderRadius: 0,
  },

  customTooltip: {
    zIndex: 99,
    pointerEvents: "none",
    marginTop: 10,
    minWidth: 190,
    padding: 20,
    borderRadius: 4,
    backgroundColor: theme.palette.text.primary,
    filter: "drop-shadow(rgba(0, 0, 0, 0.8) 0 2px 10px)",
    "&:before": {
      content: "''",
      position: "absolute",
      top: -4,
      left: "50%",
      transform: "translateX(-50%) rotate(45deg)",
      width: 15,
      height: 15,
      borderRadius: 2,
      backgroundColor: theme.palette.text.primary,
    },
  },
}));

const CoinInfo = ({ logoUrl, children, size, description, healthFactor }) => {
  const classes = useStyles();

  const [isOpen, setOpen] = useState(false);
  const popoverAnchor = useRef(null);

  const popoverHover = () => {
    setOpen(true);
  };

  const popoverLeave = () => {
    setOpen(false);
  };

  return (
    <Grid container alignItems="center" spacing={1} wrap="nowrap">
      <Grid item>
        <Avatar
          src={logoUrl}
          alt="Coin Logo"
          classes={{ root: clsx(classes[size]) }}
          className={classes.avatar}
        />
      </Grid>
      <Grid item>
        <Typography>{children}</Typography>
        {description && <Typography color="primary">{description}</Typography>}
        {healthFactor && (
          <>
            <Box
              ref={popoverAnchor}
              onMouseEnter={popoverHover}
              onMouseLeave={popoverLeave}
            >
              <HealthFactorProgressBar value={healthFactor} size="small" />
            </Box>
            <Popper
              open={isOpen}
              anchorEl={popoverAnchor.current}
              className={classes.customTooltip}
              PaperProps={{
                onMouseEnter: popoverHover,
                onMouseLeave: popoverLeave,
              }}
            >
              <HealthTooltip value={healthFactor} />
            </Popper>
          </>
        )}
      </Grid>
    </Grid>
  );
};

CoinInfo.propTypes = {
  logoUrl: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  size: PropTypes.oneOf(["large", "default"]),
  description: PropTypes.string,
  healthFactor: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};

CoinInfo.defaultProps = {
  size: "default",
  description: "",
  healthFactor: false,
};

export default CoinInfo;
