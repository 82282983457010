import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";

import theme from "theme";
import { WalletProvider } from "context";
import {
  Header,
  Footer,
  ScrollToTop,
  NotificationProvider,
  Analytics,
} from "components";
// import { ApolloProvider } from "@apollo/client";

// import { client } from "./utils/grapql.client";

import Routes from "./Routes";

const useGlobalStyles = makeStyles({
  "@global": {
    body: {
      height: "100%",
      backgroundImage: "url(../assets/images/body-background.png)",
    },
  },
});

const useStyles = makeStyles(() => ({
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },

  content: {
    flex: "1 0 auto",
  },
}));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const App = () => {
  useGlobalStyles();
  const classes = useStyles();

  return (
    <QueryClientProvider client={queryClient}>
      <WalletProvider>
        {/* <ApolloProvider client={client}> */}
        <Router>
          <Analytics />
          <ScrollToTop />
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <NotificationProvider>
              <div className={classes.mainWrapper}>
                <Header />
                <Container disableGutters={false} className={classes.content}>
                  <Routes />
                </Container>
                <Footer />
              </div>
            </NotificationProvider>
          </ThemeProvider>
        </Router>
        {/* </ApolloProvider> */}
      </WalletProvider>
    </QueryClientProvider>
  );
};

export default App;
