import { LENDING_TOKEN_ADDRESS } from "constants/NetworkChainId";

import { ethers, BigNumber } from "ethers";
import { formatFixed, parseFixed } from "@ethersproject/bignumber";
import Eth from "web3-eth";
import detectEthereumProvider from "@metamask/detect-provider";
import { errorCodes, serializeError } from "eth-rpc-errors";

import {
  PrimaryIndexToken,
  bUSDCContract,
  PriceProviderAggregatorContract,
  lendingTokenAddress,
} from "./contracts";
import { ERC20TokenABI } from "./abi";

const UINT256_MAX_INT = BigNumber.from(
  "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
);

const blocksPerDay = process.env.REACT_APP_BLOCKS_PER_DAY;

class Ethereum {
  wallet = window.ethereum;

  provider;

  chainId;

  account;

  init = (accountsChanged) => {
    this.provider = new ethers.providers.Web3Provider(this.wallet);
    this.chainId = localStorage.getItem("chainId");
    this.wallet.on("accountsChanged", ([account]) => {
      this.account = account || "";
      accountsChanged(this.account);
    });
    this.wallet.on("chainChanged", () => {
      window.location.reload();
    });
  };

  getWalletAccount = async () => {
    const [account] = await this.wallet.request({
      method: "eth_requestAccounts",
    });
    this.account = account || "";

    return this.account;
  };

  getChainId = async () => {
    const chainId = await this.wallet.request({ method: "eth_chainId" });
    return chainId;
  };

  isProviderExist = async () => {
    this.provider = await detectEthereumProvider();
    return !!this.provider;
  };

  isWalletAccountEnable = async () => {
    const [account] = await new Eth(this.wallet).getAccounts();
    return Boolean(account);
  };

  handleWalletError = (error) => {
    const { code, data } = serializeError(error);
    const message =
      data?.originalError?.error?.message ||
      data?.originalError?.reason ||
      "Transaction error";
    const { userRejectedRequest } = errorCodes.provider;

    if (userRejectedRequest === code) {
      throw new Error("userRejectedRequest");
    } else {
      throw new Error(message);
    }
  };

  getBalance = async (address) => {
    const signer = this.provider.getSigner();
    const contract = new ethers.Contract(address, ERC20TokenABI, signer);
    const balanceOfAccount = await contract.balanceOf(this.account);
    const decimals = await contract.decimals();

    return formatFixed(balanceOfAccount, decimals);
  };

  isTokenAllowance = async (address) => {
    const signer = this.provider.getSigner();
    const contract = new ethers.Contract(address, ERC20TokenABI, signer);
    const allowance = await contract.allowance(
      this.account,
      PrimaryIndexToken.address
    );

    return !BigNumber.from(allowance).isZero();
  };

  approve = async (address) => {
    try {
      const signer = this.provider.getSigner();
      const contract = new ethers.Contract(address, ERC20TokenABI, signer);

      const approved = await contract.approve(
        PrimaryIndexToken.address,
        UINT256_MAX_INT
      );
      await approved.wait();
    } catch (error) {
      this.handleWalletError(error);
    }
  };

  convertToDecimal = async (value, address) => {
    const contract = new ethers.Contract(address, ERC20TokenABI, this.provider);
    const decimals = await contract.decimals();
    return parseFixed(value, decimals);
  };

  convertFromDecimal = async (value, address) => {
    const contract = new ethers.Contract(address, ERC20TokenABI, this.provider);
    const decimals = await contract.decimals();
    return Number(formatFixed(BigNumber.from(value), decimals));
  };

  deposit = async (value, address, projectTokenAddress) => {
    try {
      const signer = this.provider.getSigner();
      const contract = new ethers.Contract(
        PrimaryIndexToken.address,
        PrimaryIndexToken.abi,
        signer
      );

      const projectTokenAmount = await this.convertToDecimal(value, address);
      const createdDeposit = await contract.deposit(
        projectTokenAddress,
        lendingTokenAddress,
        projectTokenAmount
      );
      await createdDeposit.wait();
    } catch (error) {
      this.handleWalletError(error);
    }
  };

  withdraw = async (value, address, projectTokenAddress, isMax) => {
    try {
      const signer = this.provider.getSigner();
      const contract = new ethers.Contract(
        PrimaryIndexToken.address,
        PrimaryIndexToken.abi,
        signer
      );

      let projectTokenAmountConverted;
      if (isMax) {
        projectTokenAmountConverted = ethers.constants.MaxUint256;
      } else {
        projectTokenAmountConverted = await this.convertToDecimal(
          value,
          projectTokenAddress
        );
      }
      const createdWithdraw = await contract.withdraw(
        projectTokenAddress,
        lendingTokenAddress,
        projectTokenAmountConverted
      );
      await createdWithdraw.wait();
    } catch (error) {
      this.handleWalletError(error);
    }
  };

  getPitAmount = async (prjAddress) => {
    const signer = this.provider.getSigner();
    const contract = new ethers.Contract(
      PrimaryIndexToken.address,
      PrimaryIndexToken.abi,
      signer
    );

    const pitAmount = await contract.pit(
      this.account,
      prjAddress,
      lendingTokenAddress
    );
    return Number(formatFixed(BigNumber.from(pitAmount), 6));
  };

  getPrjAmount = async (prjAddress) => {
    const signer = this.provider.getSigner();
    const contract = new ethers.Contract(
      PrimaryIndexToken.address,
      PrimaryIndexToken.abi,
      signer
    );
    const { depositedProjectTokenAmount } = await contract.getPosition(
      this.account,
      prjAddress,
      lendingTokenAddress
    );

    const convertedPrjAmount = await this.convertFromDecimal(
      depositedProjectTokenAmount,
      prjAddress
    );

    return convertedPrjAmount;
  };

  getTotalInIndex = async (address) => {
    const signer = this.provider.getSigner();
    const contract = new ethers.Contract(address, ERC20TokenABI, signer);
    const balance = await contract.balanceOf(PrimaryIndexToken.address);

    return this.convertFromDecimal(balance, address);
  };

  getBlockNumber = async () => {
    if (this.account) {
      const blockNumber = await this.provider.getBlockNumber();
      return blockNumber;
    }

    return null;
  };

  getLVR = async (address) => {
    const signer = this.provider.getSigner();
    const contract = new ethers.Contract(
      PrimaryIndexToken.address,
      PrimaryIndexToken.abi,
      signer
    );
    const projectTokenInfo = await contract.projectTokenInfo(address);

    const lvrNumerator = projectTokenInfo[3];
    const lvrDenominator = projectTokenInfo[4];

    const LVR = lvrDenominator.isZero() ? 0 : lvrNumerator / lvrDenominator;

    return LVR;
  };

  getPrice = async (address) => {
    const signer = this.provider.getSigner();
    const contract = new ethers.Contract(
      PriceProviderAggregatorContract.address,
      PriceProviderAggregatorContract.abi,
      signer
    );
    const { priceMantissa, priceDecimals } = await contract.getPrice(address);
    const price = Number(formatFixed(priceMantissa, priceDecimals));

    return price;
  };

  borrow = async (prjAddress, lendingTokenAmount, isMax) => {
    try {
      const signer = this.provider.getSigner();
      const contract = new ethers.Contract(
        PrimaryIndexToken.address,
        PrimaryIndexToken.abi,
        signer
      );

      let lendingTokenAmountConverted;
      if (isMax) {
        lendingTokenAmountConverted = ethers.constants.MaxUint256;
      } else {
        lendingTokenAmountConverted = await this.convertToDecimal(
          lendingTokenAmount.toString(),
          lendingTokenAddress
        );
      }

      const createdBorrow = await contract.borrow(
        prjAddress,
        lendingTokenAddress,
        lendingTokenAmountConverted
      );
      await createdBorrow.wait();
    } catch (error) {
      this.handleWalletError(error);
    }
  };

  repayBorrow = async (
    amountLendingToken,
    prjAddress,
    prjAmount,
    isMaxValue
  ) => {
    try {
      const signer = this.provider.getSigner();
      const contract = new ethers.Contract(
        PrimaryIndexToken.address,
        PrimaryIndexToken.abi,
        signer
      );

      const maxValue = BigNumber.from(2).pow(256).sub(1);
      const lendingTokenAmount = isMaxValue
        ? maxValue
        : await this.convertToDecimal(amountLendingToken, lendingTokenAddress);

      const createdRepay = await contract.repay(
        prjAddress,
        lendingTokenAddress,
        lendingTokenAmount
      );
      await createdRepay.wait();
    } catch (error) {
      this.handleWalletError(error);
    }
  };

  supply = async (amountLendingToken) => {
    try {
      const signer = this.provider.getSigner();
      const contract = new ethers.Contract(
        PrimaryIndexToken.address,
        PrimaryIndexToken.abi,
        signer
      );

      const amount = await this.convertToDecimal(
        amountLendingToken,
        lendingTokenAddress
      );

      const createdSupply = await contract.supply(lendingTokenAddress, amount);
      await createdSupply.wait();
    } catch (error) {
      this.handleWalletError(error);
    }
  };

  redeem = async (amountLendingToken) => {
    try {
      const signer = this.provider.getSigner();
      const contract = new ethers.Contract(
        PrimaryIndexToken.address,
        PrimaryIndexToken.abi,
        signer
      );

      const amount = await this.convertToDecimal(
        amountLendingToken,
        lendingTokenAddress
      );
      const createdRedeem = await contract.redeem(lendingTokenAddress, amount);
      await createdRedeem.wait();
    } catch (error) {
      this.handleWalletError(error);
    }
  };

  redeemUnderlying = async (amountLendingToken) => {
    try {
      const signer = this.provider.getSigner();
      const contract = new ethers.Contract(
        PrimaryIndexToken.address,
        PrimaryIndexToken.abi,
        signer
      );

      const amount = await this.convertToDecimal(
        amountLendingToken,
        lendingTokenAddress
      );
      const createdRedeem = await contract.redeemUnderlying(
        lendingTokenAddress,
        amount
      );
      await createdRedeem.wait();
    } catch (error) {
      this.handleWalletError(error);
    }
  };

  approveLendingToken = async () => {
    try {
      const signer = this.provider.getSigner();
      const contract = new ethers.Contract(
        lendingTokenAddress,
        ERC20TokenABI,
        signer
      );

      const approved = await contract.approve(
        bUSDCContract.address,
        UINT256_MAX_INT
      );
      await approved.wait();
    } catch (error) {
      this.handleWalletError(error);
    }
  };

  isLendingTokenAllowance = async (address) => {
    try {
      const signer = this.provider.getSigner();
      const contract = new ethers.Contract(address, ERC20TokenABI, signer);
      const allowance = await contract.allowance(
        this.account,
        bUSDCContract.address
      );

      return !BigNumber.from(allowance).isZero();
    } catch (error) {
      return false;
    }
  };

  getLendingTokenBalance = async () => {
    const signer = this.provider.getSigner();
    const contract = new ethers.Contract(
      bUSDCContract.address,
      ERC20TokenABI,
      signer
    );
    const balance = await contract.balanceOf(this.account);

    return this.convertFromDecimal(balance, bUSDCContract.address);
  };

  getLendingTokenBalanceOf = async () => {
    const signer = this.provider.getSigner();
    const contract = new ethers.Contract(
      LENDING_TOKEN_ADDRESS[this.chainId],
      ERC20TokenABI,
      signer
    );

    const [balance, symbol] = await Promise.all([
      contract.balanceOf(this.account),
      contract.symbol(),
    ]);

    const decimals = await contract.decimals();

    const result = {
      balance: ethers.utils.formatUnits(balance, decimals),
      symbol,
    };

    return result;
  };

  getHealthFactor = async (prjAddress) => {
    const signer = this.provider.getSigner();
    const contract = new ethers.Contract(
      PrimaryIndexToken.address,
      PrimaryIndexToken.abi,
      signer
    );
    const { healthFactorNumerator, healthFactorDenominator } =
      await contract.getPosition(this.account, prjAddress, lendingTokenAddress);

    const healthFactor = healthFactorDenominator.isZero()
      ? 0
      : healthFactorNumerator / healthFactorDenominator;

    return healthFactor;
  };

  getSupplyAPY = async () => {
    const signer = this.provider.getSigner();
    const contract = new ethers.Contract(
      bUSDCContract.address,
      bUSDCContract.abi,
      signer
    );

    const ethMantissa = 1e18;
    const daysPerYear = 365;

    const supplyRatePerBlock = await contract.supplyRatePerBlock();

    const supplyApy =
      // eslint-disable-next-line no-restricted-properties
      (Math.pow(
        (+supplyRatePerBlock / ethMantissa) * blocksPerDay + 1,
        daysPerYear
      ) -
        1) *
      100;

    return supplyApy;
  };

  getSupplyValue = async () => {
    const signer = this.provider.getSigner();
    const contract = new ethers.Contract(
      bUSDCContract.address,
      bUSDCContract.abi,
      signer
    );

    const balanceOfUnderlying = await contract.balanceOfUnderlyingView(
      this.account
    );
    const supplyValue = await this.convertFromDecimal(
      balanceOfUnderlying,
      bUSDCContract.address
    );

    return supplyValue;
  };
}

const walletInfo = new Ethereum();

export default walletInfo;
