import { createMuiTheme } from "@material-ui/core/styles";

import palette from "./palette";
import { transitions } from "./transitions";
import fontFamily from "./fontFamily";

const theme = createMuiTheme({
  palette,
  transitions,
  typography: {
    fontFamily,
  },
});

export default theme;
