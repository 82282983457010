import partition from "lodash/partition";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import { withWalletLoader } from "HOCFunction";
import { useWallet } from "hooks";
import { useEffect } from "react";
import { useActionsLog } from "./hooks";
import {
  AvailableForDepositingTable,
  AvailableToReturnTable,
  BorrowerOverview,
  BorrowerOverviewSkeleton,
  DepositedBorrowedTable,
} from "./components";

const BorrowerNewDashboard = () => {
  const { data, isLoading = true, isError = false } = useActionsLog();
  const { disconnect } = useWallet();

  const [depositedData] = partition(
    data?.coins,
    ({ prjAmount }) => prjAmount > 0
  );
  const showDepositedTable = Boolean(depositedData.length);

  useEffect(() => {
    if (isError) disconnect();
  }, [disconnect, isError]);

  return (
    <Box my={3}>
      <Grid container spacing={3}>
        {isLoading || isError ? (
          <BorrowerOverviewSkeleton />
        ) : (
          <BorrowerOverview
            infoData={data?.infoData}
            totalPitAmount={data?.totalPitAmount}
          />
        )}
      </Grid>
      {showDepositedTable && (
        <Box my={3}>
          <Grid container>
            <Grid item xs={12}>
              <DepositedBorrowedTable data={depositedData} />
            </Grid>
          </Grid>
        </Box>
      )}
      <Box my={3}>
        <Grid container spacing={6}>
          <Grid item md={6}>
            <AvailableForDepositingTable />
          </Grid>

          <Grid item md={6}>
            {true && <AvailableToReturnTable />}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default withWalletLoader(BorrowerNewDashboard);
