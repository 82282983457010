import { useCallback, useState } from "react";

import { CHARTS } from "../../constants";

import { MarketChart, IndexMarketTabs } from "..";

const MarketCharts = () => {
  const [activeTab, setActiveTab] = useState(CHARTS[0]);

  const handleTabChange = useCallback((newTab) => {
    setActiveTab(newTab);
  }, []);

  return (
    <>
      <IndexMarketTabs activeTab={activeTab} onTabChange={handleTabChange} />

      <MarketChart activeTab={activeTab} />
    </>
  );
};

export default MarketCharts;
