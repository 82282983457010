import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import { withWalletLoader } from "HOCFunction";
import { useWallet } from "hooks";

import { TableSkeleton } from "components";

import { useLenderDashboard } from "./hooks";

import {
  LenderOverview,
  SuppliedTable,
  AvailableTokensTable,
  LenderOverviewSkeleton,
} from "./components";

const LenderDashboard = () => {
  const { connected } = useWallet();
  const { isLoading, data } = useLenderDashboard();

  const showSuppliedTable = connected && data?.accountData?.supply.length;

  const renderTables = () => {
    if (isLoading) {
      return (
        <>
          <Box border="1px solid #434849">
            <TableSkeleton rows={3} />
          </Box>

          <Box border="1px solid #434849" mt={2}>
            <TableSkeleton rows={3} />
          </Box>
        </>
      );
    }

    return (
      <>
        {showSuppliedTable && (
          <SuppliedTable data={data?.accountData?.supply} />
        )}

        <Box my={2}>
          <AvailableTokensTable data={data?.accountData?.available} />
        </Box>
      </>
    );
  };

  return (
    <Box my={3}>
      <Grid container justify="center">
        <Grid item md={6}>
          {isLoading ? (
            <LenderOverviewSkeleton />
          ) : (
            <LenderOverview accountData={data?.accountData} />
          )}
        </Grid>
      </Grid>

      <Box mt={3}>
        <Grid container justify="center">
          <Grid item md={6}>
            {renderTables()}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default withWalletLoader(LenderDashboard);
