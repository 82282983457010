import { WalletContext } from "context";
import { useContext } from "react";

const useWallet = () => {
  const wallet = useContext(WalletContext);

  return {
    connect: wallet.connect,
    account: wallet.account,
    status: wallet.status,
    Status: wallet.Status,
    connected: wallet.status === wallet.Status.connected,
    connecting: wallet.status === wallet.Status.connecting,
    chainId: wallet.chainId,
  };
};

export default useWallet;
