import { useQueryClient } from "react-query";

import { EVENT_TYPES } from "app/constants";

const useCoinLogs = ({ amount, name, kind }) => {
  const queryClient = useQueryClient();

  const addToLogs = async () => {
    const prevData = queryClient.getQueryData("deposited-borrowed");
    const prevIndex = prevData.coins.findIndex((coin) => coin.name === name);

    let prevLogsLength = 0;
    if (prevData.coins[prevIndex].data?.logs) {
      prevLogsLength = prevData.coins[prevIndex].data?.logs.length;
    }

    await queryClient.invalidateQueries("deposited-borrowed");

    const currentData = queryClient.getQueryData("deposited-borrowed");
    const currentIndex = currentData.coins.findIndex(
      (coin) => coin.name === name
    );
    const currentLogsLength =
      currentData.coins[currentIndex].data?.logs?.length;

    const isEqualLogs = prevLogsLength === currentLogsLength;
    const isLendingTokenEventType =
      kind === EVENT_TYPES.repay || kind === EVENT_TYPES.borrow;

    if (isEqualLogs) {
      queryClient.setQueryData("deposited-borrowed", (oldData) => {
        const optimisticLog = {
          amount: {
            decimal: amount,
            rounded: amount,
          },
          asset: isLendingTokenEventType
            ? oldData.coins[currentIndex].data.lendingAsset
            : oldData.coins[currentIndex].data.asset,
          kind,
          date: new Date().toISOString(),
        };

        const newData = { ...oldData };

        newData.coins[currentIndex].data.logs = [
          optimisticLog,
          ...oldData.coins[currentIndex].data.logs,
        ];

        return newData;
      });
    }
  };

  return { addToLogs };
};

export default useCoinLogs;
