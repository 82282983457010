import { useCallback, useEffect, useMemo, useState } from "react";
import ReactGa from "react-ga";

import { Box, Button, Paper, Typography } from "@material-ui/core";

import { useModalState } from "hooks";

import {
  CoinInfo,
  NumericText,
  Table,
  PaperTitle,
  Dialog,
  DepositModal,
  TableSkeleton,
} from "components";

import { useAvailableCoins } from "../../hooks";

const COLUMNS = [
  {
    Header: "Asset",
    accessor: "name",
    Cell: ({
      value,
      row: {
        original: { logo },
      },
    }) => <CoinInfo logoUrl={logo}>{value}</CoinInfo>,
  },
  {
    Header: "LVR",
    accessor: "lvr",
    props: {
      align: "right",
    },
    Cell: ({ value }) => <NumericText value={value} precision={1} />,
  },
  {
    Header: "Balance",
    accessor: "balance",
    props: {
      align: "right",
    },
    Cell: ({
      value,
      row: {
        original: { symbol, price },
      },
    }) => {
      if (!value) {
        return "-";
      }

      return (
        <>
          <NumericText value={value} precision={2} suffix={symbol} />
          <Typography>
            <NumericText value={price} precision={4} moneyValue />
          </Typography>
        </>
      );
    },
  },
];

const AvailableForDepositingTable = () => {
  const { data, isLoading } = useAvailableCoins();

  const { isOpen, onOpen, onClose } = useModalState();
  const [modalData, setModalData] = useState({});

  const handleClick = useCallback(
    (value) => () => {
      setModalData(value);
      onOpen();

      ReactGa.event({
        category: "Borrower Dashboard",
        action: "Deposit",
      });
    },
    [onOpen]
  );

  const columns = useMemo(
    () => [
      ...COLUMNS,
      {
        Header: "",
        accessor: "button",
        props: {
          align: "right",
        },
        Cell: ({ row: { original } }) => (
          <Button onClick={handleClick(original)}>Deposit</Button>
        ),
      },
    ],
    [handleClick]
  );

  useEffect(() => {
    const activeToken = data?.find(
      ({ address }) => address === modalData?.address
    );
    if (activeToken) {
      setModalData(activeToken);
    }
  }, [data, modalData]);

  return (
    <Paper>
      <PaperTitle>Available for depositing</PaperTitle>

      <Box mt={1}>
        {isLoading ? (
          <TableSkeleton rows={6} />
        ) : (
          <Table columns={columns} data={data} />
        )}
      </Box>

      <Dialog open={isOpen} onClose={onClose} noPadding>
        <DepositModal data={modalData} onClose={onClose} />
      </Dialog>
    </Paper>
  );
};

export default AvailableForDepositingTable;
