import { NavLink as Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography } from "@material-ui/core";

import { useBlockNumber } from "hooks/query";

import { ReactComponent as FooterIcon } from "assets/svg/footer-icon.svg";

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: "25px 30px",
    marginTop: 50,
    backgroundColor: theme.palette.primary.main,
    borderTop: `1px solid ${theme.palette.primary.dark}`,
    [theme.breakpoints.down("md")]: {
      padding: "25px 15px",
    },
  },

  footerContainer: {
    padding: "0 30px",
    alignItems: "center",
  },

  link: {
    color: "#797C7D",
    fontSize: 14,
    transition: theme.transitions.main,
    textDecoration: "none",

    "&:hover": {
      color: theme.palette.primary.dark,
      transition: theme.transitions.main,
    },
  },

  icon: {
    marginRight: 9,
  },

  footerText: {
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-end",
    },
  },

  footerCopyright: {
    color: theme.palette.common.white,
    fontSize: 14,
  },
  latestBlock: {
    fontSize: 12,
    fontWeight: 500,
  },
}));

const Footer = () => {
  const classes = useStyles();
  const { data } = useBlockNumber();
  const blockNumber = data || "-";

  return (
    <footer className={classes.footer}>
      <Container>
        <Grid
          container
          spacing={3}
          className={classes.footerContainer}
          justify="space-between"
        >
          <Grid item container spacing={3} alignItems="center" md={6}>
            <Grid item>
              <Typography align="center" className={classes.footerCopyright}>
                2022 © Fringe
              </Typography>
            </Grid>

            <Grid item>
              <Link to="/terms-of-service" className={classes.link}>
                Terms of Service
              </Link>
            </Grid>

            <Grid item>
              <a
                href="https://docs.google.com/document/d/15FIORGEHf0FyUWb_3Hgz_TiJuh34-XfYlpDZCw9jXkE/edit"
                target="_blank"
                rel="noreferrer"
                className={classes.link}
              >
                Testnet instructions
              </a>
            </Grid>
          </Grid>

          <Grid
            item
            md={6}
            container
            alignItems="center"
            className={classes.footerText}
          >
            <FooterIcon className={classes.icon} />
            <Typography color="secondary" className={classes.latestBlock}>
              Latest block: {blockNumber}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
