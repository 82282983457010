import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import ReactGa from "react-ga";

import walletInfo from "utils/ethereum";

import { useCoinLogs } from "hooks";

const useCoinMutations = ({ projectTokenAddress, name, amount, kind }) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const { addToLogs } = useCoinLogs({
    amount,
    name,
    kind,
  });

  const handleError = useCallback(
    (error) => {
      if (error.message === "userRejectedRequest") {
        enqueueSnackbar("Transaction has been canceled", {
          variant: "warning",
        });
      } else {
        enqueueSnackbar(`Transaction rejected: ${error.message}`, {
          variant: "error",
        });
      }
    },
    [enqueueSnackbar]
  );

  const approve = useMutation(() => walletInfo.approve(projectTokenAddress), {
    onSuccess: () => {
      queryClient.invalidateQueries("available-coins");
      enqueueSnackbar("Transaction approved!", {
        variant: "success",
      });
    },
    onError: handleError,
  });

  const deposit = useMutation(
    async (value) => {
      await walletInfo.deposit(value, projectTokenAddress, projectTokenAddress);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries("available-coins");

        await addToLogs();

        enqueueSnackbar("The transaction has been confirmed successfully!", {
          variant: "success",
        });

        ReactGa.event({
          category: "Borrower Dashboard",
          action: "Deposit success",
        });
      },
      onError: handleError,
    }
  );

  const withdraw = useMutation(
    async ({ value, isMax }) => {
      await walletInfo.withdraw(
        value,
        projectTokenAddress,
        projectTokenAddress,
        isMax
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("available-coins");
        queryClient.invalidateQueries("deposited-borrowed");
        enqueueSnackbar("The transaction has been confirmed successfully!", {
          variant: "success",
        });

        ReactGa.event({
          category: "Borrower Dashboard",
          action: "Withdraw success",
        });
      },
      onError: handleError,
    }
  );

  return {
    isLoading: approve.isLoading || deposit.isLoading || withdraw.isLoading,
    approve: approve.mutate,
    deposit: deposit.mutateAsync,
    withdraw: withdraw.mutateAsync,
  };
};

export default useCoinMutations;
