import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import { Title } from "components";

const useStyles = makeStyles((theme) => ({
  date: {
    fontSize: 18,
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightBold,
  },
  subTitle: {
    fontSize: 18,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const TermsOfService = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="md">
      <Box my={3}>
        <Title>Terms of Service</Title>
      </Box>

      <Typography className={classes.date}>Updated: August 4, 2021</Typography>

      <Box mt={4} mb={6}>
        <Box mb={2}>
          <Typography color="secondary">
            Welcome to https://app.bonded.finance, a website-hosted user
            interface (the “Interface” or “App”) provided by Bonded.finance
            (“we”, “our”, or “us”). The Interface provides access to a
            decentralized protocol on the Ethereum blockchain that allows
            suppliers and borrowers of certain digital assets to participate in
            autonomous interest rate markets (the “Protocol”).
          </Typography>
        </Box>

        <Typography color="secondary">
          This Terms of Service Agreement (the “Agreement”) explains the terms
          and conditions by which you may access and use the Interface. You must
          read this Agreement carefully. By accessing or using the Interface,
          you signify that you have read, understand, and agree to be bound by
          this Agreement in its entirety. If you do not agree, you are not
          authorized to access or use the Interface.
        </Typography>
      </Box>

      <Box mt={4} mb={6}>
        <Box mb={2}>
          <Typography color="secondary" className={classes.subTitle}>
            1. Modification of ths Agreement
          </Typography>
        </Box>

        <Typography color="secondary">
          We reserve the right, in our sole discretion, to modify this Agreement
          from time to time. If we make any modifications, we will notify you by
          updating the date at the top of the Agreement and by maintaining a
          current version of the Agreement at
          https://app.bonded.finance/terms-of-service. All modifications will be
          effective when they are posted, and your continued use of the
          Interface will serve as confirmation of your acceptance of those
          modifications. If you do not agree with any modifications to this
          Agreement, you must immediately stop accessing and using the
          Interface.
        </Typography>
      </Box>

      <Box mt={4} mb={6}>
        <Box mb={2}>
          <Typography color="secondary" className={classes.subTitle}>
            2. Eligibility
          </Typography>
        </Box>

        <Typography color="secondary">
          To access or use the Interface, you must be able to form a legally
          binding contract with us. Accordingly, you represent that you are at
          least eighteen years old and have the full right, power, and authority
          to enter into and comply with the terms and conditions of this
          Agreement on behalf of yourself and any company or legal entity for
          which you may access or use the Interface. You further represent that
          you are not a citizen, resident, or member of any jurisdiction or
          group that is subject to economic sanctions by the United States, or
          where your use of the Interface would be illegal or otherwise violate
          any applicable law. You further represent that your access and use of
          the Interface will fully comply with all applicable laws and
          regulations, and that you will not access or use the Interface to
          conduct, promote, or otherwise facilitate any illegal activity.
        </Typography>
      </Box>

      <Box mt={4} mb={6}>
        <Box mb={2}>
          <Typography color="secondary" className={classes.subTitle}>
            3. Proprietary Rights
          </Typography>
        </Box>

        <Typography color="secondary">
          We own all intellectual property and other rights in the Interface and
          its contents, including (but not limited to) software, text, images,
          trademarks, service marks, copyrights, patents, and designs. Unless
          expressly authorized by us, you may not copy, modify, adapt, rent,
          license, sell, publish, distribute, or otherwise permit any third
          party to access or use the Interface or any of its contents. Provided
          that you are eligible, you are hereby granted a single, personal,
          limited license to access and use the Interface. This license is
          non-exclusive, non-transferable, and freely revocable by us at any
          time without notice or cause. Use of the Interface or its contents for
          any purpose not expressly permitted by this Agreement is strictly
          prohibited. Unlike the Interface, the Protocol is comprised entirely
          of open-source software running on the public Ethereum blockchain and
          is not our proprietary property.
        </Typography>
      </Box>

      <Box mt={4} mb={6}>
        <Box mb={2}>
          <Typography color="secondary" className={classes.subTitle}>
            4. Privacy
          </Typography>
        </Box>

        <Typography color="secondary">
          We care about your privacy. Although we will comply with all valid
          subpoena requests, we will carefully consider each request to ensure
          that it comports with the spirit and letter of the law, and we will
          not hesitate to challenge invalid, overbroad, or unconstitutional
          requests as appropriate. We use commercially reasonable safeguards to
          preserve the integrity and security of your personally identifiable
          information (“PII”) and aggregate data. However, we cannot guarantee
          that unauthorized third parties will never be able to obtain or use
          your PII or aggregate data for improper purposes. You acknowledge that
          you provide your PII and aggregate data at your own risk. By accessing
          and using the Interface, you understand and consent to our collection,
          use, and disclosure of your PII and aggregate data.
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsOfService;
