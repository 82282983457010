import { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { createChart, CrosshairMode } from "lightweight-charts";

import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { getDateRange } from "helpers/getDateRange";

import { mainChartStyles } from "./chartStyles";

const useStyles = makeStyles((theme) => ({
  floatingTooltip: {
    minWidth: 150,
    position: "absolute",
    display: "none",
    padding: theme.spacing(2),
    color: "#E2E2E2",
    backgroundColor: "#2E2E2E",
    zIndex: 99,
    pointerEvents: "none",
    borderRadius: 8,
    "& > div": {
      display: "flex",
      alignItems: "center",
      margin: "5px 0",

      "& > span": {
        width: 4,
        height: 14,
        borderRadius: 4,
        marginRight: 5,
      },
    },
  },
}));

const TradingChart = ({ data, lines, interval }) => {
  const chartContainerRef = useRef();
  const tooltipRef = useRef();

  const classes = useStyles();

  useEffect(() => {
    if (!data.length) {
      return null;
    }
    const chart = createChart(chartContainerRef.current, {
      ...mainChartStyles,
      crosshair: {
        mode: CrosshairMode.Normal,
      },
    });

    const lineSeriesArray = [];

    data.forEach((line) => {
      const lineColor = line[0].color;

      const lineSeries = chart.addLineSeries({
        priceLineColor: lineColor.color,
        color: lineColor.color,
      });

      lineSeries.setData(line);
      lineSeriesArray.push(lineSeries);
    });

    chart.timeScale().setVisibleRange({
      from: getDateRange(interval).getTime() / 1000,
      to: new Date().getTime() / 1000,
    });

    const toolTip = tooltipRef.current;

    // update tooltip
    chart.subscribeCrosshairMove(({ point, time, seriesPrices }) => {
      if (point === undefined || !time || point.x < 0 || point.y < 0) {
        toolTip.style.display = "none";
      } else {
        const price = lineSeriesArray.map((item) => seriesPrices.get(item));
        const tooltipData = price.map((item, index) => {
          if (!item) {
            return null;
          }
          const { title, color } = lines[index];

          return `
            <div>
              <span style="background-color: ${color}"></span>
              ${title}: ${Math.round(100 * item) / 100}
            </div>
          `;
        });

        toolTip.innerHTML = tooltipData.join("");

        const coordinate = lineSeriesArray[0].priceToCoordinate(price[0]);
        if (coordinate === null) {
          return;
        }
        toolTip.style.left = `${point.x + 220}px`;
        toolTip.style.top = `${point.y + 370}px`;
        toolTip.style.display = "block";
      }
    });

    return () => {
      chart.remove();
    };
  }, [data, lines, interval]);

  return (
    <Box ref={chartContainerRef} mt={2}>
      <div ref={tooltipRef} className={classes.floatingTooltip} />
    </Box>
  );
};

TradingChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        time: PropTypes.instanceOf(Date),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        color: PropTypes.string.isRequired,
      })
    )
  ).isRequired,
  lines: PropTypes.shape({
    title: PropTypes.string,
    color: PropTypes.string,
  }).isRequired,
};

export default TradingChart;
