import PropTypes from "prop-types";
import { useState, useCallback, useEffect } from "react";
import { useQueryClient } from "react-query";

import walletInfo from "utils/ethereum";

import WalletContext from "./WalletContext";

const Status = {
  connecting: "connecting",
  connected: "connected",
  walletExist: "wallet-exist",
  disconnected: "disconnected",
};

const WalletProvider = ({ children }) => {
  const queryClient = useQueryClient();
  const [account, setAccount] = useState("");
  const [status, setStatus] = useState(Status.connecting);
  const [chainId, setChainId] = useState(null);

  const refetchQueries = useCallback(async () => {
    // Re-fetch all queries after success wallet connection
    await queryClient.invalidateQueries();
  }, [queryClient]);

  const handleAccountChange = useCallback(
    async (newAccount) => {
      setAccount(newAccount);
      await refetchQueries();
    },
    [refetchQueries]
  );

  const connect = useCallback(async () => {
    const isWalletInstalled = await walletInfo.isProviderExist();

    if (isWalletInstalled) {
      const walletAccount = await walletInfo.getWalletAccount();
      const walletChainId = await walletInfo.getChainId();

      localStorage.setItem("chainId", walletChainId);

      setAccount(walletAccount);
      setStatus(Status.connected);
      setChainId(walletChainId);

      walletInfo.init(handleAccountChange);

      await refetchQueries();
    }
  }, [handleAccountChange, refetchQueries]);

  const init = useCallback(async () => {
    const isWalletInstalled = await walletInfo.isProviderExist();

    if (isWalletInstalled) {
      const enabled = await walletInfo.isWalletAccountEnable();

      if (enabled) {
        await connect();
      } else {
        setStatus(Status.walletExist);
      }
    } else {
      setStatus(Status.disconnected);
    }
  }, [connect]);

  const disconnect = useCallback(() => {
    setAccount(null);
    setStatus(Status.disconnected);
    window.location.reload();
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <WalletContext.Provider
      value={{
        account,
        connect,
        status,
        Status,
        chainId,
        disconnect,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

WalletProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WalletProvider;
