import { makeStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";

import { useToggle } from "hooks";

import { TableSkeleton } from "components";

import { useMarketIndexTokens } from "../../hooks";

import { TokensTable } from "..";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "relative",
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "transparent",
    },
  },
  collapse: {
    marginBottom: 50,
    transition: theme.transitions.main,
  },
  button: {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightBold,

    "&:hover": {
      color: theme.palette.secondary.dark,
    },
  },
  bottomPaper: {
    position: "absolute",
    left: 0,
    bottom: -20,
    width: "100%",
    paddingTop: 15,
    paddingBottom: 15,

    textAlign: "center",
    backgroundColor: "#191919",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    transition: theme.transitions.main,
    cursor: "pointer",

    "&:hover": {
      transition: theme.transitions.main,

      "& $button": {
        color: theme.palette.common.white,
      },
    },
  },
}));

const MAX_COLLAPSE_ELEMENTS = 4;

const IndexMarketTables = () => {
  const classes = useStyles();

  const [shouldShowMore, toggleShowMore] = useToggle(false);

  const { data, isLoading } = useMarketIndexTokens();

  if (isLoading) {
    return (
      <Box border="1px solid #434849" mt={2} py={2} px={3}>
        <TableSkeleton rows={6} />
      </Box>
    );
  }

  const { length } = data;

  return (
    <Paper className={classes.paper}>
      {length > MAX_COLLAPSE_ELEMENTS ? (
        <>
          <Collapse
            in={shouldShowMore}
            collapsedHeight={275}
            className={classes.collapse}
          >
            <TokensTable data={data} />
          </Collapse>
          <Paper
            className={classes.bottomPaper}
            role="button"
            onClick={toggleShowMore}
          >
            <Link component="button" href="/" className={classes.button}>
              {shouldShowMore ? "See less" : "See more"}
            </Link>
          </Paper>
        </>
      ) : (
        <TokensTable data={data} />
      )}
    </Paper>
  );
};

export default IndexMarketTables;
