import { useState, useEffect, useCallback } from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { makeStyles } from "@material-ui/core/styles";

import { linePropType } from "types/market";

import { Spinner } from "components";

import { useMarketIndex } from "../../hooks";

import { DEFAULT_INTERVAL_VALUE } from "../../constants";

import { TradingChart, ChartIntervals, ChartLinesSelect, ChartChip } from "..";

const useStyles = makeStyles((theme) => ({
  chartBox: {
    minHeight: 465,
    marginTop: theme.spacing(2),
  },
}));

const MarketChart = ({ activeTab }) => {
  const classes = useStyles();

  const [selectedInterval, setSelectedInterval] = useState(
    DEFAULT_INTERVAL_VALUE
  );
  const [activeLines, setActiveLines] = useState([activeTab]);

  const { data, isLoading } = useMarketIndex(activeLines);

  useEffect(() => {
    setActiveLines([activeTab]);
  }, [activeTab]);

  const handleSelectInterval = useCallback((newInterval) => {
    setSelectedInterval(newInterval);
  }, []);

  const handleLinesChange = (lines) => setActiveLines(lines);

  const handleRemoveLine = (line) =>
    setActiveLines((prevState) =>
      [...prevState].filter(({ title }) => title !== line.title)
    );

  const renderChart = () => {
    if (isLoading) {
      return <Spinner />;
    }

    return (
      <TradingChart
        data={data}
        lines={activeLines}
        interval={selectedInterval}
      />
    );
  };

  return (
    <Paper>
      <Box p={3}>
        <Grid container justify="space-between">
          <Grid item>
            <ChartIntervals
              selectedInterval={selectedInterval}
              onChange={handleSelectInterval}
            />
          </Grid>

          <Grid item>
            <ChartLinesSelect
              activeLines={activeLines}
              onChange={handleLinesChange}
            />
          </Grid>
        </Grid>
      </Box>

      <Box px={3}>
        <Grid container spacing={1}>
          {activeLines.map((line) => (
            <Grid item key={line.value}>
              <ChartChip line={line} onRemove={handleRemoveLine} />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box className={classes.chartBox} p={3}>
        {renderChart()}
      </Box>
    </Paper>
  );
};

MarketChart.propTypes = {
  activeTab: linePropType.isRequired,
};

export default MarketChart;
