/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import truncateText from "utils/utils";

import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Button,
  Link,
  Hidden,
  Typography,
  Container,
} from "@material-ui/core";

import { ReactComponent as EthereumIcon } from "assets/ethereum.svg";
import { ReactComponent as PolygonIcon } from "assets/polygon.svg";
import { ReactComponent as OptimismIcon } from "assets/svg/optimism.svg";
import { ReactComponent as ArbitrumIcon } from "assets/svg/arbitrum.svg";

import { PlatformSelect } from "components/Header/components/PlatformSelect";

import { useModalState, useWallet } from "hooks";
import { CHAINID_NETWORK } from "constants/NetworkChainId";

import { Dialog } from "components";
import { ConnectWalletDialog, MobileMenu } from "./components";
import { SwitchNetworkDialog } from "./components/SwitchNetworkDialog";

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    borderBottom: "1px solid #2B2B2B",
  },
  appBar: {
    padding: "16px 24px",
    border: "none",
  },

  toolbar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: 0,
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
      alignItems: "center",
    },
  },

  navigation: {
    flexGrow: 1,
    marginLeft: 40,
  },

  link: {
    paddingBottom: 3,
    borderBottom: "2px solid transparent",

    lineHeight: "20px",
    fontSize: 16,
    color: theme.palette.common.white,

    [theme.breakpoints.up("lg")]: {
      margin: theme.spacing(2),
    },

    "&:hover, &.active": {
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
      boxSizing: "border-box",
    },
  },

  button: {
    width: "100%",
    maxWidth: "400px",
    marginTop: 30,
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up("lg")]: {
      width: "auto",
      marginTop: 0,
    },
  },

  disabledButton: {
    "&.Mui-disabled": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
    },
  },

  ethereumIcon: {
    width: "2rem",
    height: "2rem",
  },

  polygonIcon: {
    width: 30,
    height: 30,
  },

  logoLink: {
    display: "flex",
    alignItems: "center",
  },
  wallet: {
    color: "#515151",
    fontSize: 15,
    lineHeight: "19px",
    fontWeight: 500,
  },
  walletAddress: {
    color: theme.palette.success.light,
    fontWeight: 500,
  },
  switchNetwork: {
    padding: 5,
    marginTop: 10,
    marginLeft: 10,
    marginRight: "auto",
    background: "rgba(243, 132, 30, 0.05)",
    [theme.breakpoints.up("lg")]: {
      width: "auto",
      marginTop: 0,
    },
    "&:hover": {
      background: "rgba(243, 132, 30, 0.05)",
    },
  },
  networkText: {
    fontSize: "1rem",
    margin: "auto",
    marginLeft: 8,
  },
  chainId: {
    color: "#fff",
    padding: 0,
  },
  buttonWrapper: {
    display: "flex",
    padding: "0 4px",
  },
  selectNetwork: {
    color: "red",
    fontSize: 14,
  },
}));

const LINKS = [
  {
    path: "/",
    label: "Borrow",
    exact: true,
  },
  {
    path: "/lend",
    label: "Lend",
    exact: false,
  },
  {
    path: "/markets",
    label: "Markets",
    exact: false,
  },
];

const Header = () => {
  const classes = useStyles();
  const { account, status, Status, chainId } = useWallet();
  const { isOpen, onOpen, onClose } = useModalState();
  const [visible, setVisible] = useState(false);

  const handleOpen = () => {
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (status === Status.walletExist) {
      onOpen();
    }
  }, [status, Status, onOpen]);

  return (
    <div className={classes.header}>
      <Container>
        <AppBar
          position="static"
          color="transparent"
          elevation={0}
          className={classes.appBar}
        >
          <Toolbar className={classes.toolbar}>
            <PlatformSelect />
            <Hidden mdDown>
              <nav className={classes.navigation}>
                {LINKS.map(({ path, label, exact }) => (
                  <Link
                    key={path}
                    to={path}
                    exact={exact}
                    component={NavLink}
                    className={classes.link}
                    underline="none"
                  >
                    {label}
                  </Link>
                ))}
              </nav>
            </Hidden>

            <Hidden lgUp>
              <MobileMenu links={LINKS} />
            </Hidden>

            {account ? (
              <>
                <Typography className={classes.wallet}>
                  Connected wallet:{" "}
                  <Typography
                    className={classes.walletAddress}
                    component="span"
                  >
                    {truncateText(account)}
                  </Typography>
                </Typography>
                <Button onClick={handleOpen} className={classes.switchNetwork}>
                  <Typography className={classes.chainId} component="span">
                    {chainId === CHAINID_NETWORK.polygon && (
                      <div className={classes.buttonWrapper}>
                        <PolygonIcon className={classes.polygonIcon} />
                        <Typography className={classes.networkText}>
                          Polygon
                        </Typography>
                      </div>
                    )}
                    {chainId === CHAINID_NETWORK.optimism && (
                      <div className={classes.buttonWrapper}>
                        <OptimismIcon className={classes.polygonIcon} />
                        <Typography className={classes.networkText}>
                          Optimism
                        </Typography>
                      </div>
                    )}
                    {chainId === CHAINID_NETWORK.arbitrum && (
                      <div className={classes.buttonWrapper}>
                        <ArbitrumIcon className={classes.polygonIcon} />
                        <Typography className={classes.networkText}>
                          Arbitrum
                        </Typography>
                      </div>
                    )}
                    {chainId === CHAINID_NETWORK.ethereum && (
                      <div className={classes.buttonWrapper}>
                        <EthereumIcon className={classes.ethereumIcon} />{" "}
                        <Typography className={classes.networkText}>
                          Ethereum
                        </Typography>
                      </div>
                    )}
                    {chainId !== CHAINID_NETWORK.polygon &&
                      chainId !== CHAINID_NETWORK.optimism &&
                      chainId !== CHAINID_NETWORK.arbitrum &&
                      chainId !== CHAINID_NETWORK.ethereum && (
                        <Typography className={classes.selectNetwork}>
                          Wrong Network
                        </Typography>
                      )}
                  </Typography>
                </Button>
              </>
            ) : (
              <Button onClick={onOpen} className={classes.button}>
                Connect Wallet
              </Button>
            )}
          </Toolbar>

          <Dialog open={isOpen} onClose={onClose}>
            <ConnectWalletDialog onClose={onClose} />
          </Dialog>
          {/* <Dialog open={visible} onClose={handleClose}>
            <SwitchNetworkDialog onClose={onClose} />
          </Dialog> */}
        </AppBar>
      </Container>
    </div>
  );
};

export default Header;
