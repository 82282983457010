import { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import { lendingTokenPropType } from "types/lendingToken";
import { Box, Button, Paper } from "@material-ui/core";

import { useModalState } from "hooks";
import { CoinInfo, NumericText, Table, PaperTitle, Dialog } from "components";
import { RedeemModal } from "..";

const COLUMNS = [
  {
    Header: "Asset",
    accessor: "name",
    props: {
      width: 140,
    },
    Cell: ({
      row: {
        original: { symbol },
      },
    }) => (
      <CoinInfo logoUrl="./assets/coins_list/usd-coin.svg">{symbol}</CoinInfo>
    ),
  },
  {
    Header: "APY",
    accessor: "apy",
    props: {
      width: 100,
    },
    Cell: ({ value }) => <NumericText value={value} precision={2} suffix="%" />,
  },
  {
    Header: "Value",
    accessor: "supplyValue",
    Cell: ({ value }) => <NumericText value={value.toString()} precision={2} />,
  },
];

const SuppliedTable = ({ data }) => {
  const { isOpen, onOpen, onClose } = useModalState();
  const [modalData, setModalData] = useState({});

  const columns = useMemo(
    () => [
      ...COLUMNS,
      {
        Header: "",
        accessor: "button",
        props: {
          align: "right",
          width: 150,
        },
        Cell: ({ row: { original } }) => (
          <Button
            fullWidth
            onClick={() => {
              setModalData(original);
              onOpen();
            }}
          >
            Withdraw
          </Button>
        ),
      },
    ],
    [onOpen, setModalData]
  );

  useEffect(() => {
    const activeToken = data.find(
      ({ address }) => address === modalData.address
    );
    if (activeToken) {
      setModalData(activeToken);
    }
  }, [data, modalData]);

  return (
    <Paper>
      <PaperTitle>Supplied</PaperTitle>
      <Box mt={2}>
        <Table columns={columns} data={data} />
      </Box>

      <Dialog open={isOpen} onClose={onClose} noPadding>
        <RedeemModal data={modalData} onClose={onClose} />
      </Dialog>
    </Paper>
  );
};

SuppliedTable.propTypes = {
  data: PropTypes.arrayOf(lendingTokenPropType).isRequired,
};

export default SuppliedTable;
