export const PrimaryIndexTokenABI = [
  "function deposit(address projectToken, address lendingToken, uint256 projectTokenAmount) public",
  "function withdraw(address projectToken, address lendingToken, uint256 projectTokenAmount) public",
  "function lvr(address prjToken) public view returns(uint8 numerator, uint8 denominator)",
  "function borrow(address projectToken, address lendingToken, uint256 lendingTokenAmount) public",
  "function repay(address projectToken, address lendingToken, uint256 lendingTokenAmount) public",
  "function healthFactor(address account, address projectToken, address lendingToken) public",
  "function pit(address account, address projectToken, address lendingToken) public view returns (uint256)",
  "function supply(address lendingToken, uint256 lendingTokenAmount) public",
  "function redeem(address lendingToken, uint256 bLendingTokenAmount) public",
  "function redeemUnderlying(address lendingToken, uint256 lendingTokenAmount) public",
  "function getPosition(address account, address projectToken, address lendingToken) public view returns (uint256 depositedProjectTokenAmount, uint256 loanBody, uint256 accrual, uint256 healthFactorNumerator, uint256 healthFactorDenominator)",
  "function projectTokenInfo(address projectToken) public view returns (bool, bool, bool, uint256, uint256, uint256, uint256, uint256, uint256)",
];

export const ERC20TokenABI = [
  "function balanceOf(address _owner) public view returns (uint256 balance)",
  "function decimals() public view returns (uint8)",
  "function symbol() public view returns (string)",
  "function allowance(address _owner, address _spender) public view returns (uint256 remaining)",
  "function approve(address _spender, uint256 _value) public returns (bool success)",
];

export const bUSDCContractABI = [
  "function supplyRatePerBlock() public view returns  (uint)",
  "function balanceOfUnderlyingView(address owner) public view returns (uint)",
  "function totalBorrows() public view returns (uint256)",
];

export const PriceProviderAggregatorABI = [
  "function getPrice(address token) public view returns (uint256 priceMantissa, uint8 priceDecimals)",
];

// projectTokenInfo returns struct Ratio {
//         uint8 numerator;
//         uint8 denominator;
//     }
//
//     struct ProjectTokenInfo {
//         bool isListed;
//         bool isDepositPaused;
//         bool isWithdrawPaused;
//         Ratio loanToValueRatio;
//         Ratio liquidationThresholdFactor;
//         Ratio liquidationIncentive;
//     }
