import React, { useState } from "react";
import PropTypes from "prop-types";
import { coinPropType } from "types/coin";
import { EVENT_TYPES } from "app/constants";

import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

import {
  Spinner,
  HealthFactorProgressBar,
  DialogLogo,
  DialogApplyButton,
  DialogTotal,
  NumericTextField,
} from "components";

import { useWallet } from "hooks";
import { useCoinMutations } from "hooks/mutation";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    minWidth: 400,
    color: theme.palette.primary.main,
    paddingLeft: 0,
    paddingRight: 0,
    width: 429,

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      minWidth: "100%",
      height: "100%",
      width: "100%",
    },
  },

  contentInner: {
    position: "relative",
    backgroundColor: "#F8F8F8",
    [theme.breakpoints.down("sm")]: {
      flex: 1,
    },
  },
}));

const WithdrawModal = ({
  data: {
    name,
    logo,
    allowance,
    address: prjTokenAddress,
    symbol,
    prjAmount,
    healthFactor,
    data: { prjRemaining },
  },
  onClose,
}) => {
  const classes = useStyles();
  const { connected } = useWallet();
  const [inputValue, setInputValue] = useState("");
  const { isLoading, withdraw } = useCoinMutations({
    projectTokenAddress: prjTokenAddress,
    name,
    amount: inputValue,
    kind: EVENT_TYPES.withdraw,
  });

  const maxValue = Number(prjRemaining.decimal);
  const isDisabled =
    !connected ||
    !inputValue ||
    Number(inputValue) === 0 ||
    Number(inputValue) > Number(maxValue);

  const resetInputValue = () => setInputValue("");

  const handleWithdraw = async () => {
    const isMax = prjRemaining.decimal === inputValue;
    try {
      await withdraw({ value: inputValue, isMax });
      onClose();
    } catch (error) {
      console.log(error);
    } finally {
      resetInputValue();
    }
  };

  return (
    <>
      <DialogLogo logoUrl={logo} name={name} />

      {isLoading && <Spinner position="absolute" color="success" />}

      <Box pt={5} p={0} className={classes.rootContainer}>
        <NumericTextField
          value={inputValue}
          onChange={setInputValue}
          maxValue={prjRemaining.decimal}
        />

        {allowance && (
          <Box px={2} py={2} mt={2} className={classes.contentInner}>
            <HealthFactorProgressBar value={healthFactor} />
          </Box>
        )}

        <Box>
          {allowance ? (
            <DialogApplyButton disabled={isDisabled} onClick={handleWithdraw}>
              Withdraw
            </DialogApplyButton>
          ) : (
            <DialogApplyButton disabled>
              No balance to withdraw
            </DialogApplyButton>
          )}
          <DialogTotal
            title="Currently Supplying"
            value={prjAmount}
            currency={symbol}
          />
        </Box>
      </Box>
    </>
  );
};

WithdrawModal.propTypes = {
  data: coinPropType.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default WithdrawModal;
