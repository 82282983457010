import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import { Dialog as MuiDialog } from "@material-ui/core";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
// import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },

  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },

  noPadding: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
  },
}));

const Dialog = ({ children, open, onClose, noPadding }) => {
  const classes = useStyles();
  // const theme = useTheme();
  // const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const noPaddingClassName = clsx({ [classes.noPadding]: noPadding });

  return (
    <MuiDialog
      open={open}
      classes={{ paper: noPaddingClassName }}
      // fullScreen={isMobileScreen}
      onClose={onClose}
    >
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <MuiDialogContent
        classes={{
          root: noPaddingClassName,
        }}
      >
        {children}
      </MuiDialogContent>
    </MuiDialog>
  );
};

Dialog.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  noPadding: PropTypes.bool,
};

Dialog.defaultProps = {
  noPadding: false,
};

export default Dialog;
