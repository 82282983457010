import PropTypes from "prop-types";

import { lendingTokenPropType } from "./lendingToken";

export const coinPropType = PropTypes.shape({
  uuid: PropTypes.string,
  address: PropTypes.string,
  name: PropTypes.string,
  logo: PropTypes.string,
  symbol: PropTypes.string,
  allowance: PropTypes.bool,
  balance: PropTypes.string,
  pitAmount: PropTypes.number,
  prjAmount: PropTypes.number,
  totalInIndex: PropTypes.number,
  lvr: PropTypes.number,
  prjId: PropTypes.number,
  lendingToken: lendingTokenPropType,
  healthFactor: PropTypes.number,
});
