import { Dialog, Typography, makeStyles } from "@material-ui/core";
import ShareTwitterButton from "components/Button/ShareTwitterButton";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  modal: {
    padding: "20px 40px",
  },
}));
const ShareModal = ({ open, onCloseModal, data, type }) => {
  const classes = useStyles();

  const textBorrow = `I have just taken a $${data?.amount} ${
    data.token
  } loan at ${Number(data.apy).toFixed(2)}% APY collateralized by ${
    data.colToken
  } on @fringefinance, a lending protocol that supports small-cap collateral assets. #DeFi4Everyone`;

  const textLending = `I have just deposited $${data?.amount} of ${
    data?.token
  } to earn ${Number(data.apy).toFixed(
    2
  )}% APY on @fringefinance, a lending protocol that supports small-cap collateral assets. #DeFi4Everyone`;
  return (
    <Dialog open={open} onClose={onCloseModal} onBackdropClick={onCloseModal}>
      <Typography className={classes.modal}>
        Share your action on twitter
      </Typography>
      <ShareTwitterButton
        title={type === "borrow" ? textBorrow : textLending}
        url={process.env.REACT_APP_SHARE_LINK || "https://app.fringe.fi/"}
      />
    </Dialog>
  );
};
ShareModal.propTypes = {
  open: Boolean.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};
export default ShareModal;
