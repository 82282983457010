import { useQuery } from "react-query";

import { marketAPI } from "utils/api";

const TOTAL =
  "total_pit,total_amount_collateral_deposited,total_outstanding,collateral_vs_loan_ration,apy";

const useMarketIndexTotal = () =>
  useQuery("market-total", async () => {
    const { data } = await marketAPI.getTotals(TOTAL);

    return data;
  });

export default useMarketIndexTotal;
