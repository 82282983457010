export const CHAINID_NETWORK = {
  arbitrum: process.env.REACT_APP_ARBITRUM_CHAIN_ID,
  optimism: process.env.REACT_APP_OPTIMISM_CHAIN_ID,
  polygon: process.env.REACT_APP_POLYGON_CHAIN_ID,
  ethereum: process.env.REACT_APP_CHAIN_ID,
};

export const CHAINID_WITH_API = {
  [CHAINID_NETWORK.polygon]: process.env.REACT_APP_API_URL_POLYGON,
  [CHAINID_NETWORK.arbitrum]: process.env.REACT_APP_API_URL_ARBITRUM,
  [CHAINID_NETWORK.ethereum]: process.env.REACT_APP_API_URL,
  [CHAINID_NETWORK.optimism]: process.env.REACT_APP_API_URL_OPTIMISM,
};

// Address of contract hooks

export const PRIMARY_INDEX_TOKEN = {
  [CHAINID_NETWORK.polygon]: process.env.REACT_APP_SC_PRIMARY_INDEX_TOKEN,
  [CHAINID_NETWORK.arbitrum]: process.env.REACT_APP_SC_PRIMARY_INDEX_TOKEN_ARB,
  [CHAINID_NETWORK.ethereum]: process.env.REACT_APP_SC_PRIMARY_INDEX_TOKEN,
  [CHAINID_NETWORK.optimism]:
    process.env.REACT_APP_SC_PRIMARY_INDEX_TOKEN_OPTIMISM,
};

export const BUSDC_CONTRACT = {
  [CHAINID_NETWORK.arbitrum]: process.env.REACT_APP_SC_BUSDC_ADDRESS_ARB,
  [CHAINID_NETWORK.ethereum]: process.env.REACT_APP_SC_BUSDC_ADDRESS,
  [CHAINID_NETWORK.optimism]: process.env.REACT_APP_SC_BUSDC_ADDRESS_OPTIMISM,
};

export const PRICE_PROVIDER_AGGREGATOR_CONTRACT = {
  [CHAINID_NETWORK.arbitrum]:
    process.env.REACT_APP_PRICE_PROVIDER_AGGREGATOR_ARB,
  [CHAINID_NETWORK.ethereum]: process.env.REACT_APP_PRICE_PROVIDER_AGGREGATOR,
  [CHAINID_NETWORK.optimism]:
    process.env.REACT_APP_PRICE_PROVIDER_AGGREGATOR_OPTIMISM,
};

export const LENDING_TOKEN_ADDRESS = {
  [CHAINID_NETWORK.arbitrum]: process.env.REACT_APP_LENDING_TOKEN_ADDRESS_ARB,
  [CHAINID_NETWORK.ethereum]: process.env.REACT_APP_LENDING_TOKEN_ADDRESS,
  [CHAINID_NETWORK.optimism]:
    process.env.REACT_APP_LENDING_TOKEN_ADDRESS_OPTIMISM,
};
