import { useState } from "react";
import PropTypes from "prop-types";
import { coinPropType } from "types/coin";
import { EVENT_TYPES } from "app/constants";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";

import {
  Spinner,
  HealthFactorProgressBar,
  DialogLogo,
  DialogApplyButton,
  DialogTotal,
  NumericTextField,
} from "components";

import { useWallet } from "hooks";
import { useCoinMutations } from "hooks/mutation";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    minWidth: 400,
    color: theme.palette.primary.main,
    paddingLeft: 0,
    paddingRight: 0,
    width: 429,

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      minWidth: "100%",
      height: "100%",
      width: "100%",
    },
  },
  contentInner: {
    position: "relative",
    backgroundColor: "#F8F8F8",
    [theme.breakpoints.down("sm")]: {
      flex: 1,
    },
  },
}));

const DepositModal = ({
  data: {
    name,
    balance,
    logo,
    allowance,
    address: prjTokenAddress,
    symbol,
    healthFactor,
  },
  onClose,
}) => {
  const classes = useStyles();
  const { connected } = useWallet();
  const [inputValue, setInputValue] = useState("");
  const { isLoading, approve, deposit } = useCoinMutations({
    projectTokenAddress: prjTokenAddress,
    name,
    amount: inputValue,
    kind: EVENT_TYPES.deposit,
  });

  const maxValue = balance;
  const isDisabled =
    !connected ||
    !inputValue ||
    Number(inputValue) === 0 ||
    Number(inputValue) > Number(maxValue);

  const resetInputValue = () => setInputValue("");

  const handleEnable = () => {
    approve();
  };

  const handleDeposit = async () => {
    try {
      await deposit(inputValue);
      onClose();
    } finally {
      resetInputValue();
    }
  };

  return (
    <>
      <DialogLogo logoUrl={logo} name={name} />

      {isLoading && <Spinner position="absolute" color="success" />}

      <Box pt={5} p={0} className={classes.rootContainer}>
        {allowance ? (
          <NumericTextField
            value={inputValue}
            onChange={setInputValue}
            maxValue={maxValue}
          />
        ) : (
          <Box m={3}>
            <Typography color="primary">
              To Deposit {name}, you need to enable it first.
            </Typography>
          </Box>
        )}

        {allowance && (
          <Box px={2} py={2} mt={2} className={classes.contentInner}>
            <HealthFactorProgressBar value={healthFactor} />
          </Box>
        )}

        <Box>
          {allowance ? (
            <DialogApplyButton disabled={isDisabled} onClick={handleDeposit}>
              Deposit
            </DialogApplyButton>
          ) : (
            <DialogApplyButton disabled={!connected} onClick={handleEnable}>
              Enable
            </DialogApplyButton>
          )}
          <DialogTotal
            title="Wallet Balance"
            value={balance}
            currency={symbol}
          />
        </Box>
      </Box>
    </>
  );
};

DepositModal.propTypes = {
  data: coinPropType.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DepositModal;
