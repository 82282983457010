import { useState } from "react";
import PropTypes from "prop-types";
import { lendingTokenPropType } from "types/lendingToken";
import { EVENT_TYPES } from "app/constants";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Grid } from "@material-ui/core";

import {
  Spinner,
  CoinInfo,
  NumericText,
  DialogLogo,
  DialogApplyButton,
  DialogTotal,
  NumericTextField,
} from "components";

import { useWallet } from "hooks";
import { useLendingTokenMutations } from "hooks/mutation";

import logoImage from "assets/svg/footer-icon.svg";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    minWidth: 400,
    color: theme.palette.primary.main,
    paddingLeft: 0,
    paddingRight: 0,
    width: 429,

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      minWidth: "100%",
      height: "100%",
      width: "100%",
    },
  },
  contentInner: {
    position: "relative",
    backgroundColor: "#F8F8F8",
    [theme.breakpoints.down("sm")]: {
      flex: 1,
    },
  },
}));

const SupplyModal = ({
  data: { name, balance, allowance, apy, symbol },
  onClose,
}) => {
  const classes = useStyles();
  const { connected } = useWallet();
  const [inputValue, setInputValue] = useState("");

  const { isLoading, approve, supply } = useLendingTokenMutations({
    name,
    amount: inputValue,
    kind: EVENT_TYPES.deposit,
  });

  const maxValue = balance;
  const isDisabled =
    !connected ||
    !inputValue ||
    Number(inputValue) === 0 ||
    Number(inputValue) > Number(maxValue);

  const resetInputValue = () => setInputValue("");

  const handleEnable = () => {
    approve();
  };

  const handleSubmit = async () => {
    try {
      await supply(inputValue);
      onClose();
    } finally {
      resetInputValue();
    }
  };

  return (
    <>
      <DialogLogo logoUrl="./assets/coins_list/usd-coin.svg" name={symbol} />

      {isLoading && <Spinner position="absolute" color="success" />}

      <Box pt={5} p={0} className={classes.rootContainer}>
        {allowance ? (
          <NumericTextField
            value={inputValue}
            onChange={setInputValue}
            maxValue={maxValue}
          />
        ) : (
          <Box m={3}>
            <Typography color="primary">
              To Supply {symbol}, you need to enable it first.
            </Typography>
          </Box>
        )}

        <Box className={classes.contentInner} mt={2}>
          <Box py={2} px={2}>
            <Grid container alignItems="center" justify="space-between">
              <Grid item md={6}>
                <CoinInfo logoUrl={logoImage}>Supply APY</CoinInfo>
              </Grid>
              <Grid item>
                <Typography color="primary">
                  <NumericText value={apy} suffix="%" />
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box>
          {allowance ? (
            <DialogApplyButton disabled={isDisabled} onClick={handleSubmit}>
              Deposit
            </DialogApplyButton>
          ) : (
            <DialogApplyButton disabled={!connected} onClick={handleEnable}>
              Enable
            </DialogApplyButton>
          )}
          <DialogTotal
            title="Wallet Balance"
            value={balance}
            currency={symbol}
          />
        </Box>
      </Box>
    </>
  );
};

SupplyModal.propTypes = {
  data: lendingTokenPropType.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SupplyModal;
