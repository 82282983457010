import partition from "lodash/partition";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import { withWalletLoader } from "HOCFunction";
import { useActionsLog } from "./hooks";

import {
  BorrowerOverview,
  BorrowerOverviewSkeleton,
  AvailableForDepositingTable,
  AvailableToReturnTable,
  DepositedBorrowedTable,
} from "./components";

const BorrowerDashboard = () => {
  const { data, isLoading } = useActionsLog();

  const [depositedData] = partition(
    data?.coins || [],
    ({ prjAmount }) => prjAmount > 0
  );
  const showDepositedTable = Boolean(depositedData.length);

  return (
    <Box my={3}>
      <Grid container spacing={3}>
        {isLoading ? (
          <BorrowerOverviewSkeleton />
        ) : (
          data && (
            <BorrowerOverview
              infoData={data?.infoData}
              totalPitAmount={data?.totalPitAmount}
            />
          )
        )}
      </Grid>
      {showDepositedTable && (
        <Box my={3}>
          <Grid container>
            <Grid item xs={12}>
              <DepositedBorrowedTable data={depositedData} />
            </Grid>
          </Grid>
        </Box>
      )}
      <Box my={3}>
        <Grid container spacing={6}>
          <Grid item md={6}>
            <AvailableForDepositingTable />
          </Grid>

          <Grid item md={6}>
            <AvailableToReturnTable />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default withWalletLoader(BorrowerDashboard);
