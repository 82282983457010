import { CHAIN_ID } from "app/constants";
import axios from "axios";
import { CHAINID_WITH_API } from "constants/NetworkChainId";

const axiosInstance = axios.create({
  baseURL: CHAINID_WITH_API[CHAIN_ID],
  headers: { "Content-Type": "application/json; charset=utf-8" },
});

axiosInstance.interceptors.response.use((response) => response.data);

export default axiosInstance;
