import { useMemo } from "react";
import PropTypes from "prop-types";
import { tokenPropType } from "types/token";

import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import { Table, CoinInfo, NumericText } from "components";

import { ReactComponent as DangerIcon } from "assets/svg/danger.svg";

import tokenIcon from "assets/prj-coin.png";

const useStyles = makeStyles(() => ({
  table: {
    marginLeft: 30,
    marginRight: 30,
    width: "auto",

    "& thead > tr": {
      verticalAlign: "middle",

      "& > th": {
        borderTop: "none",
      },
    },
    "& tbody td": {
      borderBottom: "none",
      fontWeight: 500,

      "&:nth-child(1), &:nth-child(2)": {
        minWidth: 130,
      },
    },
  },
  values: {
    fontWeight: 500,
  },
}));

const TokensTable = ({ data }) => {
  const classes = useStyles();
  const columns = useMemo(
    () => [
      {
        Header: "Token",
        accessor: "name",
        Cell: ({
          value,
          row: {
            original: { icon },
          },
        }) => <CoinInfo logoUrl={icon || tokenIcon}>{value}</CoinInfo>,
      },
      {
        Header: "Deposited",
        accessor: "totalDeposited",
        Cell: ({
          value,
          row: {
            original: { slag },
          },
        }) => (
          <Typography color="secondary" className={classes.values}>
            <NumericText value={value.decimal} suffix={slag} />
          </Typography>
        ),
      },
      {
        Header: "LVR",
        accessor: "lvr",
        Cell: ({ value }) => (
          <Typography color="secondary" className={classes.values}>
            <NumericText value={value.decimal} precision={1} />
          </Typography>
        ),
      },
      {
        Header: () => (
          <Box display="flex" alignItems="center">
            PIT
            <Tooltip title="PIT Tokens equivalent" arrow placement="top">
              <DangerIcon />
            </Tooltip>
          </Box>
        ),
        accessor: "totalPit",
        Cell: ({ value }) => (
          <Typography color="secondary" className={classes.values}>
            <NumericText value={value.rounded} />
          </Typography>
        ),
      },
      {
        Header: () => (
          <Box display="flex" alignItems="center">
            Borrowed
            <Tooltip title="Total Borrowed" arrow placement="top">
              <DangerIcon />
            </Tooltip>
          </Box>
        ),
        accessor: "totalBorrowed",
        Cell: ({ value }) => (
          <Typography color="secondary" className={classes.values}>
            <NumericText value={value.rounded} moneyValue />
          </Typography>
        ),
      },
      {
        Header: () => (
          <Box display="flex" alignItems="center">
            Outstanding
            <Tooltip title="Total Outstanding Amount" arrow placement="top">
              <DangerIcon />
            </Tooltip>
          </Box>
        ),
        accessor: "totalOutstanding",
        Cell: ({ value }) => (
          <Typography color="secondary" className={classes.values}>
            <NumericText value={value.rounded} moneyValue />
          </Typography>
        ),
      },
      {
        Header: () => (
          <Box display="flex" alignItems="center">
            Borrowing Level
            <Tooltip
              title="Borrowing level for token / Current borrowing level"
              arrow
              placement="top"
            >
              <DangerIcon />
            </Tooltip>
          </Box>
        ),
        accessor: "borrowingLevel",
        Cell: ({
          value,
          row: {
            original: { currentBorrowingLevel },
          },
        }) => (
          <>
            <Typography color="secondary" className={classes.values}>
              <NumericText value={value.rounded} moneyValue />
            </Typography>
            <Typography color="secondary" className={classes.values}>
              <NumericText
                value={currentBorrowingLevel.rounded * 100}
                suffix="%"
              />
            </Typography>
          </>
        ),
      },
    ],
    [classes.values]
  );

  return <Table columns={columns} data={data} className={classes.table} />;
};

TokensTable.propTypes = {
  data: PropTypes.arrayOf(tokenPropType).isRequired,
};

export default TokensTable;
