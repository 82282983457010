import PropTypes from "prop-types";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import { useWallet } from "hooks";
import { NumericText } from "components";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    fontFamily: "Mulish",
    lineHeight: "21px",
    color: "#434849",
  },
  subTitle: {
    fontSize: 26,
    fontFamily: "Mulish",
    lineHeight: "36px",
    color: "#AFB0B1",

    marginTop: theme.spacing(2),
  },
  divider: {
    backgroundColor: "#434849",
    marginLeft: 20,
    marginRight: 20,
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),

    "& > .MuiGrid-item": {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  price: {
    fontSize: 52,
    fontFamily: "Mulish",
    marginTop: theme.spacing(1),
    lineHeight: "64px",
  },
  priceTitle: {
    fontFamily: "Mulish",
  },
}));

const LenderOverview = ({
  accountData: { totalSupplyValue, lendingTokenBalance },
}) => {
  const classes = useStyles();
  const { connected } = useWallet();

  return (
    <Grid item container spacing={6} className={classes.container}>
      <Grid item>
        <Typography
          color="secondary"
          variant="h6"
          className={classes.priceTitle}
        >
          Deposited Total Value
        </Typography>

        <Typography color="secondary" className={classes.price}>
          <NumericText
            value={connected ? totalSupplyValue?.toString() : 0}
            moneyValue
          />
        </Typography>
      </Grid>

      <Divider orientation="vertical" flexItem className={classes.divider} />

      <Grid item>
        <Box mt={3}>
          <Typography color="primary" className={classes.title}>
            fTokens in wallet
          </Typography>

          <Typography color="secondary" className={classes.subTitle}>
            <NumericText
              value={connected ? lendingTokenBalance.toString() : 0}
            />
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

LenderOverview.propTypes = {
  accountData: PropTypes.shape({
    totalSupplyValue: PropTypes.number,
    lendingTokenBalance: PropTypes.number,
  }).isRequired,
};

export default LenderOverview;
