import PropTypes from "prop-types";

import NumberFormat from "react-number-format";

const CURRENCY = "$";

const NumericText = ({ precision, value, suffix, moneyValue }) => {
  const defaultNumberFormatProps = {
    displayType: "text",
    thousandSeparator: true,
    prefix: moneyValue ? CURRENCY : "",
    suffix: suffix === "" || suffix === "%" ? suffix : ` ${suffix}`,
  };
  const isCompactMoneyValue = moneyValue && moneyValue.compact;

  if (isCompactMoneyValue) {
    const formattedValue = new Intl.NumberFormat("en", {
      notation: "compact",
    }).format(value);

    return (
      <NumberFormat
        {...defaultNumberFormatProps}
        value={formattedValue}
        isNumericString
      />
    );
  }

  return (
    <NumberFormat
      {...defaultNumberFormatProps}
      value={value}
      decimalScale={precision}
      fixedDecimalScale
    />
  );
};

NumericText.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  precision: PropTypes.number,
  suffix: PropTypes.string,
  moneyValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      compact: PropTypes.bool,
    }),
  ]),
};

NumericText.defaultProps = {
  value: "",
  precision: 2,
  suffix: "",
  moneyValue: false,
};

export default NumericText;
