import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { ReactComponent as EthereumIcon } from "assets/ethereum.svg";
import { ReactComponent as PolygonIcon } from "assets/polygon.svg";
import { ReactComponent as OptimismIcon } from "assets/svg/optimism.svg";
import { ReactComponent as ArbitrumIcon } from "assets/svg/arbitrum.svg";
import useSwitchNetwork from "hooks/useSwitchNetwork";
import { useWallet } from "hooks";
import {
  PolygonNetwork,
  OptimismNetwork,
  ArbitrumNetwork,
  EthereumNetwork,
} from "constants/NetworkNode";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      width: 300,
    },
    width: 400,
  },

  box: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 30,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  link: {
    color: theme.palette.secondary.main,
    textDecoration: "none",
    transition: theme.transitions.main,
    "&:hover": {
      color: theme.palette.secondary.dark,
      transition: theme.transitions.main,
    },
  },

  buttonWrapper: {
    alignItems: "center",
    width: "100%",
    marginTop: 25,
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      marginTop: 55,
      marginBottom: 50,
    },
  },

  button: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 10,
    alignItems: "center",
    width: "100%",
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.info.main}`,
    borderRadius: 5,
    transition: theme.transitions.main,
    "&:hover": {
      backgroundColor: "transparent",
      border: `1px solid ${theme.palette.secondary.main}`,
      transition: theme.transitions.main,
    },
    "&:hover $walletName": {
      color: theme.palette.secondary.main,
      transition: theme.transitions.main,
    },
  },

  walletName: {
    marginLeft: 90,
    color: theme.palette.primary.light,
    fontSize: 24,
    transition: theme.transitions.main,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 60,
    },
  },

  connectText: {
    marginTop: 20,
  },

  buttonInner: {
    display: "flex",
    alignItems: "center",
  },

  ethereumIcon: {
    width: "auto",
    height: "auto",
  },

  polygonIcon: {
    width: 30,
    height: 30,
  },
  arbitrumIcon: {
    width: 30,
    height: 30,
  },

  loader: {
    display: "block",
    width: "100px",
    height: "100px",
    margin: "40px auto 30px auto",
    objectFit: "contain",
  },

  backArrow: {
    position: "absolute",
    top: "20px",
    left: "20px",
    cursor: "pointer",
  },

  text: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
}));

const SwitchNetworkDialog = () => {
  const classes = useStyles();

  const { chainId } = useWallet();
  const { switchNetwork } = useSwitchNetwork();

  return (
    <Box className={classes.root}>
      <Box className={classes.box}>
        <Box>
          <Typography variant="h4" align="center">
            Select network
          </Typography>

          <Box mt={1}>
            <Typography variant="body1" align="center" color="primary">
              Choose your network
            </Typography>
          </Box>
        </Box>

        <Box className={classes.buttonWrapper}>
          {/* Polygon */}
          <Button
            onClick={() =>
              switchNetwork(
                PolygonNetwork.chainId,
                PolygonNetwork.rpcUrls,
                PolygonNetwork.name,
                PolygonNetwork.blockUrl
              )
            }
            disabled={chainId === PolygonNetwork.chainId}
            className={classes.button}
          >
            <Box className={classes.buttonInner}>
              <PolygonIcon className={classes.polygonIcon} />
              <Typography className={classes.walletName}>Polygon</Typography>
            </Box>
          </Button>

          {/* Optimism */}
          <Button
            onClick={() =>
              switchNetwork(
                OptimismNetwork.chainId,
                OptimismNetwork.rpcUrls,
                OptimismNetwork.name,
                OptimismNetwork.blockUrl
              )
            }
            disabled={chainId === OptimismNetwork.chainId}
            className={classes.button}
          >
            <Box className={classes.buttonInner}>
              <OptimismIcon className={classes.polygonIcon} />
              <Typography className={classes.walletName}>Optimism</Typography>
            </Box>
          </Button>

          {/* Arbitrum */}
          <Button
            onClick={() => {
              switchNetwork(
                ArbitrumNetwork.chainId,
                ArbitrumNetwork.rpcUrls,
                ArbitrumNetwork.name,
                ArbitrumNetwork.blockUrl
              );
            }}
            disabled={chainId === ArbitrumNetwork?.chainId?.toLowerCase()}
            className={classes.button}
          >
            <Box className={classes.buttonInner}>
              <ArbitrumIcon className={classes.arbitrumIcon} />
              <Typography className={classes.walletName}>Arbitrum</Typography>
            </Box>
          </Button>

          <Button
            onClick={() =>
              switchNetwork(
                EthereumNetwork.chainId,
                EthereumNetwork.rpcUrls,
                EthereumNetwork.name,
                EthereumNetwork.blockUrl
              )
            }
            disabled={chainId === EthereumNetwork?.chainId?.toLowerCase()}
            className={classes.button}
          >
            <Box className={classes.buttonInner}>
              <EthereumIcon className={classes.ethereumIcon} />
              <Typography className={classes.walletName}>Ethereum</Typography>
            </Box>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SwitchNetworkDialog;
