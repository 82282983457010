import React, { useState } from "react";
import PropTypes from "prop-types";
import floor from "lodash/floor";

import { DECIMAL_SCALE, EVENT_TYPES } from "app/constants";
import { coinPropType } from "types/coin";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";

import {
  CoinInfo,
  Spinner,
  HealthFactorProgressBar,
  DialogLogo,
  DialogApplyButton,
  NumericTextField,
} from "components";

import { useLendingTokenMutations } from "hooks/mutation";

import logoImage from "assets/svg/footer-icon.svg";
import { ReactComponent as ExitIcon } from "assets/svg/exit.svg";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    minWidth: 400,
    color: theme.palette.primary.main,
    paddingLeft: 0,
    paddingRight: 0,
    width: 429,

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      minWidth: "100%",
      height: "100%",
      width: "100%",
    },
  },

  contentInner: {
    position: "relative",
    backgroundColor: "#F8F8F8",
    [theme.breakpoints.down("sm")]: {
      flex: 1,
    },
  },
  supplyBox: {
    borderBottom: "1px solid #E0E0E0",
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  supplyRates: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "18px",
  },
}));

const RepayModal = ({
  data: {
    name,
    lendingToken: { allowance },
    healthFactor,
    data,
    prjAmount,
    address,
  },
  onClose,
}) => {
  const totalOutstanding = data.totalOutstanding?.rounded || 0;

  const classes = useStyles();
  const [inputValue, setInputValue] = useState("");
  const { isLoading, repay, approve } = useLendingTokenMutations({
    name,
    amount: inputValue,
    kind: EVENT_TYPES.repay,
  });

  const maxValue = floor(totalOutstanding, DECIMAL_SCALE);
  const isDisabled =
    !inputValue || Number(inputValue) === 0 || inputValue > maxValue;

  const resetInputValue = () => setInputValue("");

  const handleEnable = () => {
    approve();
  };

  const handleRepay = async () => {
    try {
      await repay({
        lendingTokenAmount: inputValue,
        prjAddress: address,
        prjAmount,
        isMaxValue: Number(inputValue) === maxValue,
      });
      onClose();
    } catch (error) {
      console.log(error);
    } finally {
      resetInputValue();
    }
  };

  return (
    <>
      <DialogLogo
        logoUrl="./assets/coins_list/usd-coin.svg"
        name={data.lendingAsset}
      />

      {isLoading && <Spinner position="absolute" color="success" />}

      <Box pt={5} p={0} className={classes.rootContainer}>
        {allowance ? (
          <NumericTextField
            value={inputValue}
            onChange={setInputValue}
            maxValue={maxValue}
            decimalScale={DECIMAL_SCALE}
          />
        ) : (
          <Box m={3}>
            <Typography color="primary" align="center">
              To use {data.lendingAsset}, you need to enable it first.
            </Typography>
          </Box>
        )}

        {allowance && (
          <Box px={2} pb={2} mt={2} className={classes.contentInner}>
            <Box py={2}>
              <Typography color="textSecondary" className={classes.supplyRates}>
                Supply Rates <ExitIcon />
              </Typography>
            </Box>

            <Box className={classes.supplyBox}>
              <Grid container alignItems="center" justify="space-between">
                <Grid item md={6}>
                  <CoinInfo logoUrl={logoImage}>Distribution APY</CoinInfo>
                </Grid>
                <Grid item>
                  <Typography color="primary">- %</Typography>
                </Grid>
              </Grid>
            </Box>

            <HealthFactorProgressBar value={healthFactor} />
          </Box>
        )}

        <Box>
          {allowance ? (
            <DialogApplyButton disabled={isDisabled} onClick={handleRepay}>
              Repay
            </DialogApplyButton>
          ) : (
            <DialogApplyButton onClick={handleEnable}>Enable</DialogApplyButton>
          )}
        </Box>
      </Box>
    </>
  );
};

RepayModal.propTypes = {
  data: coinPropType.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RepayModal;
