import { useQuery } from "react-query";

import { getDateRange } from "helpers/getDateRange";

import { CHART_COLORS } from "app/constants";

import { marketAPI } from "utils/api";

const startDate = getDateRange("year");

const getMarketIndexData = async (select) => {
  const { data } = await marketAPI.getMarketIndexData(select, startDate);

  return data;
};

const useMarketIndex = (select) =>
  useQuery(["market-index-data", select], async () => {
    const selectedLinesData = select.map((line) => line.value).join(",");

    const data = await getMarketIndexData(selectedLinesData);

    const formattedData = data?.lines.map((item) =>
      item.dots.map(({ time, value }) => ({
        time,
        value: value.rounded,
        color: CHART_COLORS[item.kind],
      }))
    );

    return formattedData;
  });

export default useMarketIndex;
