import { useMemo } from "react";

import { Box, Paper } from "@material-ui/core";

import {
  CoinInfo,
  NumericText,
  Table,
  PaperTitle,
  TableSkeleton,
} from "components";

import { useAccountBalance } from "../../hooks";

const COLUMNS = [
  {
    Header: "Asset",
    accessor: "symbol",
    props: {
      width: 200,
    },
    Cell: ({ value }) => (
      <CoinInfo logoUrl="./assets/coins_list/usd-coin.svg">{value}</CoinInfo>
    ),
  },
  {
    Header: "Balance",
    accessor: "balance",
    Cell: ({ value }) => <NumericText value={value} precision={2} />,
  },
];

const AvailableToReturnTable = () => {
  const columns = useMemo(() => COLUMNS, []);

  const { data, isLoading } = useAccountBalance();

  return (
    <Paper>
      <PaperTitle>Wallet Balance</PaperTitle>
      <Box mt={2}>
        {isLoading ? (
          <TableSkeleton />
        ) : (
          <Table columns={columns} data={data} />
        )}
      </Box>
    </Paper>
  );
};

export default AvailableToReturnTable;
