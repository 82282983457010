import theme from "../theme";
import { primary } from "../palette";

const MuiPaper = {
  props: {
    variant: "elevation",
  },
  overrides: {
    root: {
      backgroundColor: primary.main,
      border: "1px solid #434849",

      padding: 15,

      [theme.breakpoints.up("sm")]: {
        padding: 0,
      },
    },

    rounded: {
      borderRadius: 2,
    },

    elevation1: {
      boxShadow: "none",
    },
  },
};

export default MuiPaper;
