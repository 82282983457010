import PropTypes from "prop-types";

import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

import clsx from "clsx";

import { INTERVALS } from "../../constants";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: "#2E2E2E",
    borderRadius: 4,
    color: "#959595",

    "&:hover": {
      backgroundColor: "#2E2E2E",
      color: theme.palette.common.white,
    },
  },
  buttonGroupItem: {
    "&:not(:last-child)": {
      border: "none",
    },
  },
  activeButton: {
    color: theme.palette.common.white,
  },
}));

const ChartIntervals = ({ selectedInterval, onChange }) => {
  const classes = useStyles();

  const handleSelectInterval = (interval) => () => {
    onChange(interval);
  };
  return (
    <ButtonGroup
      variant="contained"
      disableElevation
      disableRipple
      classes={{
        groupedContainedHorizontal: classes.buttonGroupItem,
      }}
    >
      {INTERVALS.map(({ name, value }) => {
        const active = value === selectedInterval;

        return (
          <Button
            key={value}
            size="small"
            onClick={handleSelectInterval(value)}
            className={clsx(
              {
                [classes.activeButton]: active,
              },
              classes.button
            )}
          >
            {name}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};

ChartIntervals.propTypes = {
  selectedInterval: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ChartIntervals;
