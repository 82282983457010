import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import ReactGa from "react-ga";

import { useSnackbar } from "notistack";

import walletInfo from "utils/ethereum";

import { useCoinLogs } from "hooks";

const useLendingTokenMutations = ({ name, amount, kind }) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { addToLogs } = useCoinLogs({
    amount,
    name,
    kind,
  });

  const handleError = useCallback(
    (error) => {
      if (error.message === "userRejectedRequest") {
        enqueueSnackbar("Transaction has been canceled", {
          variant: "warning",
        });
      } else {
        enqueueSnackbar(`Transaction rejected: ${error.message}`, {
          variant: "error",
        });
      }
    },
    [enqueueSnackbar]
  );

  const approve = useMutation(() => walletInfo.approveLendingToken(), {
    onSuccess: () => {
      queryClient.invalidateQueries("lender-dashboard");
      queryClient.invalidateQueries("deposited-borrowed");
      enqueueSnackbar("Transaction approved!", {
        variant: "success",
      });
    },
    onError: handleError,
  });

  const supply = useMutation(
    (lendingTokenAmount) => walletInfo.supply(lendingTokenAmount),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("lender-dashboard");
        enqueueSnackbar("The transaction has been confirmed successfully!", {
          variant: "success",
        });
      },
      onError: handleError,
    }
  );

  const redeem = useMutation(
    (lendingTokenAmount) => walletInfo.redeem(lendingTokenAmount),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("lender-dashboard");
        enqueueSnackbar("The transaction has been confirmed successfully!", {
          variant: "success",
        });
      },
      onError: handleError,
    }
  );

  const redeemUnderlying = useMutation(
    (lendingTokenAmount) => walletInfo.redeemUnderlying(lendingTokenAmount),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("lender-dashboard");
        enqueueSnackbar("The transaction has been confirmed successfully!", {
          variant: "success",
        });
      },
      onError: handleError,
    }
  );

  const borrow = useMutation(
    async ({ prjAddress, prjAmount, isMax }) => {
      await walletInfo.borrow(prjAddress, prjAmount, isMax);
    },
    {
      onSuccess: async () => {
        await addToLogs();
        queryClient.invalidateQueries("account-balance");
        enqueueSnackbar("The transaction has been confirmed successfully!", {
          variant: "success",
        });

        ReactGa.event({
          category: "Borrower Dashboard",
          action: "Borrow success",
        });
      },
      onError: handleError,
    }
  );

  const repay = useMutation(
    async ({ lendingTokenAmount, prjAddress, prjAmount, isMaxValue }) => {
      await walletInfo.repayBorrow(
        lendingTokenAmount,
        prjAddress,
        prjAmount,
        isMaxValue
      );
    },
    {
      onSuccess: async () => {
        await addToLogs();
        queryClient.invalidateQueries("account-balance");
        enqueueSnackbar("The transaction has been confirmed successfully!", {
          variant: "success",
        });

        ReactGa.event({
          category: "Borrower Dashboard",
          action: "Repay success",
        });
      },
      onError: handleError,
    }
  );

  return {
    isLoading:
      approve.isLoading ||
      supply.isLoading ||
      redeem.isLoading ||
      redeemUnderlying.isLoading ||
      borrow.isLoading ||
      repay.isLoading,
    approve: approve.mutateAsync,
    supply: supply.mutateAsync,
    redeem: redeem.mutateAsync,
    redeemUnderlying: redeemUnderlying.mutateAsync,
    borrow: borrow.mutateAsync,
    repay: repay.mutateAsync,
  };
};

export default useLendingTokenMutations;
