import { useState } from "react";
import PropTypes from "prop-types";
import ReactGa from "react-ga";

import { NavLink as Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { useWallet } from "hooks";

import { ReactComponent as LogoIcon } from "assets/svg/logo.svg";
import { ReactComponent as MetamaskLogo } from "assets/svg/MetamaskIcon.svg";
import { ReactComponent as RightArrow } from "assets/svg/arrow-right.svg";
import { ReactComponent as LeftArrow } from "assets/svg/arrow-left.svg";
import WalletLoader from "assets/gif/walletLoader.gif";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
    },
  },

  box: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  link: {
    color: theme.palette.secondary.main,
    textDecoration: "none",
    transition: theme.transitions.main,
    "&:hover": {
      color: theme.palette.secondary.dark,
      transition: theme.transitions.main,
    },
  },

  buttonWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginTop: 25,
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      marginTop: 55,
      marginBottom: 50,
    },
  },

  button: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.info.main}`,
    borderRadius: 5,
    transition: theme.transitions.main,
    "&:hover": {
      backgroundColor: "transparent",
      border: `1px solid ${theme.palette.secondary.main}`,
      transition: theme.transitions.main,
    },
    "&:hover $walletName": {
      color: theme.palette.secondary.main,
      transition: theme.transitions.main,
    },
  },

  walletName: {
    marginLeft: 15,
    color: theme.palette.primary.light,
    fontSize: 24,
    transition: theme.transitions.main,
  },

  connectText: {
    marginTop: 20,
  },

  buttonInner: {
    display: "flex",
    alignItems: "center",
  },

  metamaskIcon: {
    width: 54,
    height: 52,
  },

  loader: {
    display: "block",
    width: "100px",
    height: "100px",
    margin: "40px auto 30px auto",
    objectFit: "contain",
  },

  backArrow: {
    position: "absolute",
    top: "20px",
    left: "20px",
    cursor: "pointer",
  },

  text: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
}));

const ConnectWalletDialog = ({ onClose }) => {
  const classes = useStyles();
  const { connect } = useWallet();
  const [isWalletConnecting, setWalletConnecting] = useState(false);

  const handleMetamaskConnect = async () => {
    try {
      setWalletConnecting(true);
      await connect();

      ReactGa.event({
        category: "Wallet",
        action: "Connect Wallet",
      });

      onClose();
    } catch {
      setWalletConnecting(false);
    }
  };

  const backStep = () => {
    setWalletConnecting(false);
  };

  return (
    <Box className={classes.root}>
      {isWalletConnecting ? (
        <Box className={classes.box}>
          <Box my={2}>
            <LeftArrow className={classes.backArrow} onClick={backStep} />
            <Typography variant="h4" align="center">
              Unlock wallet
            </Typography>
            <Typography
              variant="body2"
              align="center"
              className={classes.connectText}
            >
              You may need to click the extension
            </Typography>
            <img src={WalletLoader} alt="loader" className={classes.loader} />
          </Box>
        </Box>
      ) : (
        <Box className={classes.box}>
          <Box my={2}>
            <LogoIcon />
          </Box>

          <Box>
            <Typography variant="h4" align="center">
              Connect wallet
            </Typography>

            <Box mt={1}>
              <Typography variant="body1" align="center" color="primary">
                To start using Fringe
              </Typography>
            </Box>
          </Box>

          <Box className={classes.buttonWrapper}>
            <Button onClick={handleMetamaskConnect} className={classes.button}>
              <Box className={classes.buttonInner}>
                <MetamaskLogo className={classes.metamaskIcon} />
                <Typography className={classes.walletName}>Metamask</Typography>
              </Box>
              <RightArrow />
            </Button>
          </Box>
        </Box>
      )}

      <Box my={2} className={classes.text}>
        <Typography>
          By connecting, I accept{" "}
          <Link
            to="/terms-of-service"
            className={classes.link}
            onClick={onClose}
          >
            Fringe&#39;s Terms of Service
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

ConnectWalletDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ConnectWalletDialog;
