import { useQuery } from "react-query";

import { coinAPI } from "utils/api";
import walletInfo from "utils/ethereum";

const useAvailableCoins = () =>
  useQuery("available-coins", async () => {
    const [{ data }] = await Promise.all([coinAPI.getCoins()]);

    const connected = Boolean(walletInfo.account);

    if (connected) {
      const formattedData = data.map(async (item) => {
        const [balance, allowance, tokenPrice, healthFactor, lvr] =
          await Promise.all([
            walletInfo.getBalance(item.address),
            walletInfo.isTokenAllowance(item.address),
            walletInfo.getPrice(item.address),
            walletInfo.getHealthFactor(item.address),
            walletInfo.getLVR(item.address),
          ]);

        return {
          ...item,
          balance,
          allowance,
          healthFactor,
          lvr,
          price: balance * tokenPrice,
        };
      });

      const newData = await Promise.all(formattedData);

      return newData;
    }

    return data;
  });

export default useAvailableCoins;
