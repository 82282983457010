import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";

import { IndexMarketTables, MarketCharts } from "./components";

const IndexMarket = () => (
  <Container maxWidth="md">
    <Box mt={3}>
      <MarketCharts />

      <Box my={3}>
        <IndexMarketTables />
      </Box>
    </Box>
  </Container>
);

export default IndexMarket;
