export const mainChartStyles = {
  height: 400,
  width: 900,
  priceScale: {
    borderColor: "#DBE2EA",
  },
  timeScale: {
    lockVisibleTimeRangeOnResize: true,
    timeVisible: true,
    borderColor: "#434849",
  },

  grid: {
    vertLines: {
      color: "transparent",
    },
    horzLines: {
      color: "#6E747B",
    },
  },
  layout: {
    backgroundColor: "#191919",
    textColor: "#6E747B",
  },
  rightPriceScale: {
    visible: false,
  },
  leftPriceScale: {
    visible: true,
  },
};
