import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

import { makeStyles } from "@material-ui/core/styles";
import { Input, Button, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  button: {
    paddingTop: 0,
    backgroundColor: "transparent",
    color: theme.palette.success.main,

    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.success.main,
    },
  },

  buttonLabel: {
    borderBottom: `1px solid ${theme.palette.success.main}`,
    transition: theme.transitions.main,
    textTransform: "uppercase",

    "&:hover": {
      borderBottom: "1px solid transparent",
      transition: theme.transitions.main,
    },
  },
}));

const NumericTextField = ({
  value,
  onChange,
  maxValue,
  buttonText,
  placeholder,
  decimalScale,
}) => {
  const classes = useStyles();

  const onValueChange = ({ value: inputValue }) => onChange(inputValue);

  const onButtonClick = () => {
    onChange(maxValue);
  };

  return (
    <Grid container direction="column" alignItems="center">
      <NumberFormat
        value={value}
        customInput={Input}
        thousandSeparator=""
        isNumericString
        allowNegative={false}
        allowLeadingZeros={false}
        placeholder={placeholder}
        onValueChange={onValueChange}
        decimalScale={decimalScale}
      />

      <Button
        onClick={onButtonClick}
        classes={{ root: classes.button, label: classes.buttonLabel }}
      >
        {buttonText}
      </Button>
    </Grid>
  );
};

NumericTextField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  maxValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  buttonText: PropTypes.string,
  placeholder: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types, react/require-default-props
  decimalScale: PropTypes.number,
};

NumericTextField.defaultProps = {
  buttonText: "Max",
  placeholder: "0",
};

export default NumericTextField;
