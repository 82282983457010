export const PolygonNetwork = {
  chainId: process.env.REACT_APP_POLYGON_CHAIN_ID,
  rpcUrls: process.env.REACT_APP_POLYGON_RPCURLS,
  name: process.env.REACT_APP_POLYGON_NAME,
  blockUrl: process.env.REACT_APP_POLYGON_BLOCKURL,
};

export const RinkebyNetwork = {
  chainId: process.env.REACT_APP_RINKEBY_CHAIN_ID,
  rpcUrls: process.env.REACT_APP_RINKEBY_RPCURLS,
  name: process.env.REACT_APP_RINKEBY_NAME,
  blockUrl: process.env.REACT_APP_RINKEBY_BLOCKURL,
};

export const OptimismNetwork = {
  chainId: process.env.REACT_APP_OPTIMISM_CHAIN_ID,
  rpcUrls: process.env.REACT_APP_OPTIMISM_RPCURLS,
  name: process.env.REACT_APP_OPTIMISM_NAME,
  blockUrl: process.env.REACT_APP_OPTIMISM_BLOCKURL,
};

export const ArbitrumNetwork = {
  chainId: process.env.REACT_APP_ARBITRUM_CHAIN_ID,
  rpcUrls: process.env.REACT_APP_ARBITRUM_RPCURLS,
  name: process.env.REACT_APP_ARBITRUM_NAME,
  blockUrl: process.env.REACT_APP_ARBITRUM_BLOCKURL,
};

export const EthereumNetwork = {
  chainId: process.env.REACT_APP_CHAIN_ID,
  rpcUrls: process.env.REACT_APP_RPCURLS,
  name: process.env.REACT_APP_NAME,
  blockUrl: process.env.REACT_APP_BLOCKURL,
};
