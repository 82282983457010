/* eslint-disable no-unused-vars */
import { CHAIN_ID, CHAIN_ERROR_MESSAGE } from "app/constants";
import { useWallet } from "hooks";

import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";

import { Spinner } from "components";
import { CHAINID_NETWORK } from "constants/NetworkChainId";

const useStyles = makeStyles({
  alert: {
    justifyContent: "center",
  },
});

const withWalletLoader = (Component) => () => {
  const classes = useStyles();
  const { connecting, connected, chainId } = useWallet();

  if (!connected) return <></>;

  if (connecting) {
    return <Spinner />;
  }

  if (connected && chainId !== CHAIN_ID) {
    return (
      <Grid container justify="center">
        <Grid item md={4}>
          <Alert
            variant="filled"
            severity="error"
            icon={false}
            className={classes.alert}
          >
            {CHAIN_ERROR_MESSAGE}
          </Alert>
        </Grid>
      </Grid>
    );
  }

  return <Component />;
};

export default withWalletLoader;
